import create from "zustand";
import { devtools } from "zustand/middleware";

import authStore from "./authStore";
import globalStateStore from "./globalStateStore";

const storeCombiner = (set, get) => ({
  ...authStore(set, get),
  ...globalStateStore(set, get),
});

const useStore = create(devtools(storeCombiner));

export default useStore;
