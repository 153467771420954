import dynamic from "next/dynamic";
import { Toaster } from "react-hot-toast";
import { SHOW_PROFILE_SETUP } from "../constants/constance";
import LoginModal from "./auth/LoginModal/LoginModal";

const Modal = dynamic(() => import("@qoohoo/common/library/modals/Modal"));
const GetStartedModal = dynamic(() => import("./GetStartedModal"));

const FullScreenLoader = dynamic(() =>
  import("./library/loader/FullScreenLoader")
);

const Portals = () => {
  return (
    <>
      <Modal />
      <LoginModal />
      <GetStartedModal />
      <FullScreenLoader />
      <Toaster />
    </>
  );
};

export default Portals;
