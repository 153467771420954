import amplitude from "amplitude-js";
import { isBrowser } from "../../constants/isBrowser";

function setUniqueUserID(id) {
  if (isBrowser) {
    amplitude.getInstance().setUserId(id);
  }
}

function removeUniqueUserID() {
  if (isBrowser) {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }
}

function addProperties(properties) {
  if (isBrowser) {
    amplitude.getInstance().setUserProperties(properties);
  }
}

function clearProperties() {
  if (isBrowser) {
    amplitude.getInstance().clearUserProperties();
  }
}

function pageView(label, data = {}, callback = null) {
  if (isBrowser) {
    const { pathname, href } = window.location;
    const pageTitle = document.title;
    const pageUrl = new URL(href);
    const referer = pageUrl.searchParams.get("referer");
    const intent = pageUrl.searchParams.get("intent");

    amplitude.getInstance().logEvent(
      label,
      {
        title: pageTitle,
        url: href,
        path: pathname,
        referer,
        intent,
        ...data,
      },
      callback
    );
  }
}

function customEvent(type, data = {}, callback = null) {
  if (isBrowser) {
    amplitude.getInstance().logEvent(
      type,
      {
        location: window.location.href,
        ...data,
      },
      callback
    );
  }
}

function click(elementType, label, data = {}, callback = null) {
  customEvent(
    label,
    { actionType: "click", elementType: elementType, label: label, ...data },
    callback
  );
}

function buttonClick(label, data = {}, callback = null) {
  click("button", label, data, callback);
}

function linkClick(label, data = {}, callback = null) {
  click("link", label, data, callback);
}

function success(label, description, data = {}, callback = null) {
  customEvent(
    label,
    { actionType: "success", label: label, description: description, ...data },
    callback
  );
}

function error(label, description, data = {}, callback = null) {
  customEvent(
    label,
    { actionType: "error", label: label, description: description, ...data },
    callback
  );
}

export {
  pageView,
  customEvent,
  click,
  buttonClick,
  linkClick,
  success,
  error,
  setUniqueUserID,
  removeUniqueUserID,
  addProperties,
  clearProperties,
};
