import { ModalController, prepareModalState } from "./modalController";

const globalStateStore = (set, get) => ({
  loader: false,
  selectedPlanUid: null,
  readyToPay: false,
  showMeetingDetailsModal: false,
  showOnboardingModal: false,
  superlinkOnboardingModalState: prepareModalState(false, false),
  SuperlinkOnboardingModalController: () =>
    ModalController("superlinkOnboardingModalState", set, get),
  setSelectedPlanUid: (uid) => {
    set({ selectedPlanUid: uid });
  },
  setReadyToPay: (state) => {
    set({ readyToPay: state });
  },
  setShowMeetingDetailsModal: (state) => {
    set({ showMeetingDetailsModal: state });
  },
  setShowOnboardingModal: (state) => {
    set({ showOnboardingModal: state });
  },
  setLoaderState: (state) => {
    set({ loader: state });
  },
  isGetStartedModalOpened: false,
  setIsGetStartedModalOpened: (state) => {
    set({ isGetStartedModalOpened: state });
  },
  country: null,
  setCountry: (state) => {
    set({ country: state });
  },
});

export default globalStateStore;
