export const bootIntercom = (INTERCOM_APP_ID, extraData = {}) => {
  if(window?.Intercom) {
      window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: INTERCOM_APP_ID,
          ...extraData
      });
  }
}

export const shutdownIntercom = () => {
  if(window?.Intercom) {
    window.Intercom('shutdown');
  }
}

export const updateIntercom = (extraData = {}) => {
  if(window?.Intercom) {
    window.Intercom('update', extraData)
  }
}
  
  export const hideIntercom = () => {
    if(window?.Intercom) {
      window.Intercom('update', {
        "hide_default_launcher": true
      });
    }
  }
  
  export const showIntercom = () => {
    if(window?.Intercom) {
      window.Intercom('update', {
        "hide_default_launcher": false
      });
    }
  };