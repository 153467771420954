import { ModalController, prepareModalState } from "./modalController";

const authStore = (set, get) => {
  return {
    loading: false,
    loginModalState: prepareModalState(false, false),
    orderSummaryModalState: prepareModalState(false, false),
    authFailedModalOpened: false,
    isAuthFailed: false,
    shouldUpdateProfile: false,
    setLoading: (state) => {
      set({ loading: state });
    },
    LoginModalController: () => ModalController("loginModalState", set, get),
    OrderSummaryModalController: () => ModalController("orderSummaryModalState", set, get),
    mobileConnectModalOpened: false,
    setMobileConnectModalOpenState: (state) => {
      set({ mobileConnectModalOpened: state });
    },
    setAuthFailedModalOpenState: (state) => {
      set({ authFailedModalOpened: state });
    },
    setIsAuthFailed: (state) => {
      set({ isAuthFailed: state });
    },
    setShouldUpdateProfile: (state) => {
      set({ shouldUpdateProfile: state });
    },
  };
};

export default authStore;
