export const waitForElement = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const getValue = (css, parent, delta) => {
  let value, percentage;

  if (css.indexOf("px") !== -1) {
    value = parseFloat(css);
  } else if (css.indexOf("%") !== -1) {
    value = parseFloat(css);
    percentage = value / 100;
    value = percentage * parent;

    if (delta) {
      value -= delta * percentage;
    }
  } else {
    value = parent;
  }

  return value;
};

export const calculateAreaFromCSS = (obj) => {
  let css = window.getComputedStyle(obj.el);

  // Force no-repeat and padding-box
  obj.el.style.backgroundRepeat = "no-repeat";
  obj.el.style.backgroundOrigin = "padding-box";

  // Background Size
  let size = css.backgroundSize.split(" ");
  let width = size[0];
  let height = size[1] === undefined ? "auto" : size[1];

  let parentRatio = obj.el.clientWidth / obj.el.clientHeight;
  let imgRatio = obj.img.naturalWidth / obj.img.naturalHeight;

  if (width === "cover") {
    if (parentRatio >= imgRatio) {
      width = "100%";
      height = "auto";
    } else {
      width = "auto";
      size[0] = "auto";
      height = "100%";
    }
  } else if (width === "contain") {
    if (1 / parentRatio < 1 / imgRatio) {
      width = "auto";
      size[0] = "auto";
      height = "100%";
    } else {
      width = "100%";
      height = "auto";
    }
  }

  if (width === "auto") {
    width = obj.img.naturalWidth;
  } else {
    width = getValue(width, obj.el.clientWidth);
  }

  if (height === "auto") {
    height = (width / obj.img.naturalWidth) * obj.img.naturalHeight;
  } else {
    height = getValue(height, obj.el.clientHeight);
  }

  if (size[0] === "auto" && size[1] !== "auto") {
    width = (height / obj.img.naturalHeight) * obj.img.naturalWidth;
  }

  let position = css.backgroundPosition;

  // Fix inconsistencies between browsers
  if (position === "top") {
    position = "50% 0%";
  } else if (position === "left") {
    position = "0% 50%";
  } else if (position === "right") {
    position = "100% 50%";
  } else if (position === "bottom") {
    position = "50% 100%";
  } else if (position === "center") {
    position = "50% 50%";
  }

  position = position.split(" ");

  let x;
  let y;

  // Two-value syntax vs Four-value syntax
  if (position.length === 4) {
    x = position[1];
    y = position[3];
  } else {
    x = position[0];
    y = position[1];
  }

  // Use a default value
  y = y || "50%";

  // Background Position
  x = getValue(x, obj.el.clientWidth, width);
  y = getValue(y, obj.el.clientHeight, height);

  // Take care of ex: background-position: right 20px bottom 20px;
  if (position.length === 4) {
    if (position[0] === "right") {
      x = obj.el.clientWidth - obj.img.naturalWidth - x;
    }

    if (position[2] === "bottom") {
      y = obj.el.clientHeight - obj.img.naturalHeight - y;
    }
  }

  x += obj.el.getBoundingClientRect().left;
  y += obj.el.getBoundingClientRect().top;

  return {
    left: Math.floor(x),
    right: Math.floor(x + width),
    top: Math.floor(y),
    bottom: Math.floor(y + height),
    width: Math.floor(width),
    height: Math.floor(height),
  };
};

export const getArea = (obj) => {
  let area, image, parent;

  if (obj.nodeType) {
    let rect = obj.getBoundingClientRect();

    // Clone ClientRect for modification purposes
    area = {
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom,
      width: rect.width,
      height: rect.height,
    };

    parent = obj.parentNode;
    image = obj;
  } else {
    area = calculateAreaFromCSS(obj);
    parent = obj.el;
    image = obj.img;
  }

  parent = parent.getBoundingClientRect();

  area.imageTop = 0;
  area.imageLeft = 0;
  area.imageWidth = image.naturalWidth;
  area.imageHeight = image.naturalHeight;

  const ratio = area.imageHeight / area.height;
  let delta;

  if (area.top < parent.top) {
    delta = parent.top - area.top;
    area.imageTop = ratio * delta;
    area.imageHeight -= ratio * delta;
    area.top += delta;
    area.height -= delta;
  }

  if (area.left < parent.left) {
    delta = parent.left - area.left;
    area.imageLeft += ratio * delta;
    area.imageWidth -= ratio * delta;
    area.width -= delta;
    area.left += delta;
  }

  if (area.bottom > parent.bottom) {
    delta = area.bottom - parent.bottom;
    area.imageHeight -= ratio * delta;
    area.height -= delta;
  }

  if (area.right > parent.right) {
    delta = area.right - parent.right;
    area.imageWidth -= ratio * delta;
    area.width -= delta;
  }

  area.imageTop = Math.floor(area.imageTop);
  area.imageLeft = Math.floor(area.imageLeft);
  area.imageHeight = Math.floor(area.imageHeight);
  area.imageWidth = Math.floor(area.imageWidth);

  return area;
};

export const drawImage = (ctx, image) => {
  const area = getArea(image);
  const _image = image.nodeType ? image : image.img;
  
  if (
    area.imageWidth > 0 &&
    area.imageHeight > 0 &&
    area.width > 0 &&
    area.height > 0
  ) {
    ctx.drawImage(
      _image,
      area.imageLeft,
      area.imageTop,
      area.imageWidth,
      area.imageHeight,
      area.left,
      area.top,
      area.width,
      area.height
    );
  }
};

export const checkForCSSImage = (element) => {
  let _element = element;

  if (element.tagName !== "IMG") {
    let url = window.getComputedStyle(element).backgroundImage;

    // Ignore multiple backgrounds
    if (url.split(/,url|, url/).length > 1) {
      throw "Multiple backgrounds are not supported";
    }

    if (url && url !== "none") {
      _element = {
        img: new Image(),
        el: element,
      };

      url = url.slice(4, -1);
      url = url.replace(/"/g, "");

      _element.img.src = url;
    } else {
      throw "Element is not an <img> but does not have a background-image";
    }
  }

  return _element;
};

export const getElement = (selector, convertToImage) => {
  let element = selector;

  if (typeof selector === "string") {
    element = document.querySelector(selector);
  } else if (selector && selector.nodeType === 1) {
    element = selector;
  }

  if (!element) {
    throw "Element not found";
  } else {
    if (convertToImage) {
      element = checkForCSSImage(element);
    }
  }

  return element;
};

export const isInside = (a, b, viewport) => {
  a = (a.nodeType ? a : a.el).getBoundingClientRect();
  b = b === viewport ? b : (b.nodeType ? b : b.el).getBoundingClientRect();

  return !(
    a.right < b.left ||
    a.left > b.right ||
    a.top > b.bottom ||
    a.bottom < b.top
  );
};

export const isCanvasSupported = () => {
  const elem = document.createElement("canvas");
  return !!(elem.getContext && elem.getContext("2d"));
};

export const calculatePixelBrightness = (target, ctx, minOverlap) => {
  try {
    let dims = target.getBoundingClientRect();
    let brightness;
    let data;
    let pixels = 0;
    let delta;
    let mean = 0;
    let _minOverlap = 0;

    if (dims.width > 0 && dims.height > 0) {
      data = ctx.getImageData(dims.left, dims.top, dims.width, dims.height).data;

      for (let p = 0; p < data.length; p += 4) {

        if (data[p] === 0 && data[p + 1] === 255 && data[p + 2] === 0) {
          _minOverlap++;
        } else {
          pixels++;
          brightness = (0.2126 * data[p]) + (0.7152 * data[p + 1]) + (0.0722 * data[p + 2]);
          delta = brightness - mean;
          mean = mean + delta / pixels;
        }
      }

      if (_minOverlap <= (data.length / 4) * (1 - (minOverlap / 100))) {
        mean = mean / 255;
        return mean;
      }
    }
  } catch(err) {
    console.log(err);
  }
}

export function getIsIOS() {
  let isIOS = false;

  const userAgent = navigator.userAgent;

  // iPad on iOS 13 detection
  const isIOS13 = userAgent.includes("Mac") && "ontouchend" in document;

  const devices = ["ipad", "iphone", "ipod"];

  const platform = navigator.userAgentData?.platform || navigator.platform;

  isIOS = devices.includes(platform.toLowerCase()) || isIOS13;

  return isIOS;
}

export function isSafari() {
  const _isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return _isSafari;
}