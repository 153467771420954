import { isSameYear, format } from "date-fns";

export const sanitise = (str) => {
  /// Allowing only ASCII characters between 32 (space) to 127.
  return str.replace(/[^\x20-\x7F]/g, "");
};

export function getFormattedPercentage(perc) {
  let formattedNumber;
  const formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  };

  formattedNumber = parseFloat(perc).toLocaleString('en-US', formatOptions);

  return formattedNumber;
}

export function getFormattedNumberWithCommas(number, isInternationalFormat=false) {
  let formattedNumber;
  const formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  if(isInternationalFormat) {
    formattedNumber = parseFloat(number).toLocaleString('en-US', formatOptions);
  } else {
    formattedNumber = parseFloat(number).toLocaleString('en-IN', formatOptions);
  }

  return formattedNumber;
}

export function getFormattedAmount(amount, isInternationalFormat=false) {
  let formattedAmount = amount;
  const formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  const isNumber = typeof(amount) === 'number';

  if (isNumber) {
    if (isInternationalFormat) {
      formattedAmount = amount.toLocaleString("en-US", formatOptions);
    } else {
      formattedAmount = amount.toLocaleString("en-IN", formatOptions);
    }
  }

  return formattedAmount;
}

export function getInitialsOfName(name) {
  let initialsOfName = name || "";

  if (name) {
    const trimmedName = name.replace(/\s+/g, " ").trim();

    if (trimmedName.length) {
      const splittedName = trimmedName.split(" ");
      initialsOfName = splittedName[0][0];
    }
  }

  return initialsOfName;
}

export function getFormattedDate(date) {
  let formattedDate = "";

  if (date) {
    const isDateCurrentYear = isSameYear(new Date(date), new Date());

    if (!isDateCurrentYear) {
      formattedDate = format(new Date(date), "MMM d, yyyy");
    } else {
      formattedDate = format(new Date(date), "MMM d");
    }
  }

  return formattedDate;
}

export function combinePatterns(patterns, options = {}) {
  let regex = patterns
    .map((pattern) => pattern.source)
    .join(options.join || "");

  if (options.capture) {
    regex = `(${regex})`;
  } else if (options.nonCapture) {
    regex = `(?:${regex})`;
  }

  if (options.match) {
    regex += options.match;
  }

  return new RegExp(regex, options.flags || "");
}

export function formatBytes(bytes, decimals = 2) {
  let formattedBytes = bytes;

  if (!formattedBytes) {
    formattedBytes = "0 Bytes";
  } else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    formattedBytes =
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return formattedBytes;
}

export function isValidUsername(username) {
  const regex = /^\d+$/;

  return !regex.test(username);
}

export function removeHttp(url) {
  return url.replace(/^https?:\/\//, "");
}

export function isValidUrl(url) {
  const urlRegex =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

  return urlRegex.test(url);
}

export function isValidEmail(email) {
  const emailRegex = /\S+@\S+\.\S+/g;

  return emailRegex.test(email);
}

export function toTitleCase(string) {
  return string && string[0].toUpperCase() + string.slice(1);
}

/*
* appendQueryParamsToUrl allows only primitive data types currently.
* */
export function appendQueryParamsToUrl(url, params) {
  let finalUrl;

  const urlSugar = new URLSugar(url);

  const baseUrl = urlSugar.baseUrl;
  urlSugar.appendParams(params);
  const queryString = urlSugar.getQueryString();

  finalUrl = `${baseUrl}?${queryString}`

  return finalUrl;
}


class URLSugar {
  params = {};
  baseUrl = {};

  constructor(url) {
    const urlTokens = url.split('?');

    this.baseUrl = urlTokens[0];
    const queryString = urlTokens[1];

    if (queryString) {
      queryString.split('&').reduce((a, b) => {
        let [key, val] = b.split('=');
        a[key] = val;
        return a;
      }, this.params);
    }
  }

  baseUrl() {
    return this.baseUrl;
  }

  queryParams() {
    return this.params;
  }

  getQueryString() {
    let queryString = '';

    // iterate over key array
    queryString = Object.keys(this.params).map((key) => {
      return `${key}=${this.params[key]}`;
    }).filter(function(v) {
      return v;
    }).join('&');

    return queryString;
  }

  appendParams(params) {
    Object.keys(params).forEach(key => {
      this.appendParam(key, params[key]);
    });
  }

  appendParam(key, val) {
    this.params[key] = val;
  }
}

export function getLabeWithPrefixSuffix(label, prefix, suffix) {
  let displayLabel = label;

  if (prefix) {
    displayLabel = prefix + ' ' + label;
  }
  if(suffix) {
    displayLabel =  displayLabel + ' ' + suffix;
  }

  return displayLabel;
}


export function formatStringWithRichText (str) {
  if (!str) return "";

  let _text = str;

  _text = _text.replace(/\n/g, "<br/>");

  _text = _text.replace(/\*([^\*]+)\*/g, "<b>$1</b>"); // replace bold
  _text = _text.replace(/_([^_]+)_/g,"<i>$1</i>"); // replace italic

  return _text;
};

// reference - https://betterprogramming.pub/create-your-own-markdown-parser-bffb392a06db
export function parseMarkdown(text){ 
  let md = text;

  const rules = [
    //header rules
    [/#{6}\s?([^\n]+)/g, "<h6>$1</h6>"],
    [/#{5}\s?([^\n]+)/g, "<h5>$1</h5>"],
    [/#{4}\s?([^\n]+)/g, "<h4>$1</h4>"],
    [/#{3}\s?([^\n]+)/g, "<h3>$1</h3>"],
    [/#{2}\s?([^\n]+)/g, "<h2>$1</h2>"],
    [/#{1}\s?([^\n]+)/g, "<h1>$1</h1>"],
    
    //Lists
    [/^(\-)([^\n]+)/gm, "<ul><li>$2</li></ul>"],

    //bold, italics, strikethrough and paragragh rules
    [/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, "<b>$1</b>"],
    [/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, "<i>$1</i>"],
    [/[\~]{2}([^\~]+)[\~]{2}/g, "<del>$1</del>"],
    [/([^\n]+\n?)/g, "<p>$1</p>"],   

    //links
    [
      /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a href="$2" style="color:#384AF1;text-decoration: none;" title="$4">$1</a>'
    ],
  ];

  rules.forEach(([rule, template]) => {
    md = md.replace(rule, template);
  });

  return md;
}

export function maskNumber(num, digitsToShow = 5) {
  if(!num) return "";
  
  let maskedNum = `${num}`;

  const regexp = new RegExp(`.(?=.{${digitsToShow}})`, "g");
  
  maskedNum = maskedNum.replace(regexp, "x");

  return maskedNum;
}