/* Used when Login button is clicked. Here, profile setup screen is also shown. */

import axios from "axios";
import cls from "classnames";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { useEffect, useState, useRef } from "react";
import * as Sentry from "@sentry/nextjs";
import Turnstile from "react-turnstile";

import PhoneInput from "../../PhoneInput";
import ResendOTP from "../../ResendOTP";
import GetOtpBtn from "../../GetOtpBtn";

import useStore from "../../../store/store";
import httpf from "../../../utils/frontend/httpf";
import useSessionStore from "../../../store/sessionStore";
import * as Track from "@qoohoo/common/utils/analytics/track";
import { maskNumber } from "@qoohoo/common/utils/string";
import getCurrentPageViewByUrl from "../../../hooks/getCurrentPageViewByUrl";
import { button } from "@qoohoo/common/constants/analytics";
import { addTagsInRecording } from "@qoohoo/common/utils/analytics/clarity";
import { INITIAL_SELECTED_COUNTRY, NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY } from "../../../constants/constants";
import { SHOW_PROFILE_SETUP } from "../../../constants/constance";
import Modal from "@qoohoo/common/library/modals/Modal";
import PaymentBanner from "../../premium-content/PaymentBanner";
import Spinner from "@qoohoo/common/library/loader/Spinner";
import FormWrapper from "../../FormWrapper";
import getAuthMe from "helpers/getAuthMe";

const LoginForm = ({ onClose, customConfig = {} }) => {
  const router = useRouter();

  const userData = useSessionStore((state) => state.userData);
  const setUserData = useSessionStore((state) => state.setUserData);

  const setShowOnboardingModal = useStore(
    (state) => state.setShowOnboardingModal
  );

  const LoginModalController = useStore((state) => state.LoginModalController);
  const loginModalState = useStore((state) => state.loginModalState);

  const setShouldUpdateProfile = useStore(
    (state) => state.setShouldUpdateProfile
  );

  const country = useStore((state) => state.country);

  const [username, setUsername] = useState("");
  const [currentPageUrl, setCurrentPageUrl] = useState("");

  const [isOTPScreen, setIsOTPScreen] = useState(false);
  const [isLoadingSMS, setIsLoadingSMS] = useState(false);
  const [isLoadingWA, setIsLoadingWA] = useState(false);

  const [selectedCountry, setCountry] = useState(INITIAL_SELECTED_COUNTRY);
  const [phoneNumber, _setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [isWhatsappOptInEnabled, setIsWhatsappOptInEnabled] = useState(true);
  const [shouldSendInWhatsapp, setShouldSendInWhatsapp] = useState(false);

  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileRef = useRef(null);

  const selectedCountryCode = selectedCountry.phone_code;

  const isSuperlinkView = currentPageUrl?.split("/")[3] === "superlink";

  const current_screen = getCurrentPageViewByUrl();
  const modal_name = "otp_modal";
  const trackDetails = {
    current_user: userData?.username,
    current_screen,
    modal_name,
    countryCode: selectedCountryCode,
    mobile: phoneNumber,
    otp,
    isWhatsappOptInEnabled,
    ...userData,
  };

  useEffect(() => {
    const url = window.location.href;
    setCurrentPageUrl(url);
  }, [router.asPath]);

  useEffect(() => {
    if (isSuperlinkView) {
      let params = new URL(document.location).searchParams;
      const username = params.get("username");
      setUsername(username);
    }
  }, [router.asPath]);

  useEffect(() => {
    addTagsInRecording({ otp_modal: true, ...trackDetails });
    Track.customEvent(modal_name, {
      actionType: "modal_open",
      label: modal_name,
      ...trackDetails,
    });
  }, [userData?.uid]);

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  const saveTurnstileToken = (token) => {
    setTurnstileToken(token);
  }

  const onTurnstileError = () => {
    setTurnstileToken("dummy-token");
  }

  function resetTurnstile() {
    setTurnstileToken("");
    window?.turnstile?.reset(turnstileRef.current);
  }

  function setPhoneNumber(inputValue = "") {
    let phoneNumber = Number(inputValue);

    if (!Number.isInteger(phoneNumber)) {
      return;
    } else if (inputValue === "") {
      _setPhoneNumber("");
    } else {
      _setPhoneNumber(inputValue);
    }
  }

  function setIsLoading(isLoading) {
    if (shouldSendInWhatsapp) {
      setIsLoadingWA(isLoading);
    } else {
      setIsLoadingSMS(isLoading);
    }
  }

  function handlePhoneNumberChange(e) {
    let phoneNumber = e.target?.value;
    const isCountryCodeOfIndia = selectedCountryCode === "91";

    if (isCountryCodeOfIndia) {
      if (phoneNumber.length <= 10) {
        setPhoneNumber(phoneNumber);
      }
    } else {
      if (phoneNumber.length <= 14) {
        setPhoneNumber(phoneNumber);
      }
    }

    if (phoneNumber.length > 4) {
      setError("");
    }
  }

  function handlePhoneNumberBlur() {
    if (phoneNumber.length > 0 && phoneNumber.length <= 4) {
      setError("Enter correct phone number");
    } else {
      setError("");
    }
  }

  function handleOtpChange(e) {
    e.preventDefault();
    let otpInputValue = e.target.value;

    if (otpInputValue.length <= 6) {
      setOTP(otpInputValue);
    }
  }

  // function to handle get otp action
  function handleGetOtp(e) {
    e.preventDefault();
    setIsLoading(true);

    Track.buttonClick(button.send_otp, {
      ...trackDetails,
      mobile: phoneNumber,
      countryCode: selectedCountryCode,
      isWhatsappOtp: shouldSendInWhatsapp,
    });

    sendOtp(phoneNumber, selectedCountryCode, shouldSendInWhatsapp, 
    () => {
      setIsOTPScreen(true);
      setIsLoading(false);
    },
    () => {
      setIsOTPScreen(true);
      setIsLoading(false);
    });
  }

  // function to call api to send otp
  async function sendOtp(phoneNumber, countryCode, sendInWhatsapp, onSuccess, onError) {
    if (phoneNumber.length <= 4 || !countryCode) {
      toast.error("Enter correct phone number");
      return;
    }

    httpf
      .post(`/api/auth/mobile/request`, {
        mobile: phoneNumber,
        countryCode: countryCode,
        sendInWhatsapp: sendInWhatsapp,
        turnstileToken: turnstileToken,
      })
      .then(() => {
        toast.success("OTP sent successfully.");

        if (typeof onSuccess === "function") {
          onSuccess();
        }
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err, {
          extra: {
            countryCode: countryCode,
            mobile: maskNumber(phoneNumber)
          }
        });

        const errors =
        err?.response?.data?.data?.message ||
        err?.response?.data?.message ||
        err?.response?.data?.data?.non_field_errors ||
        err?.response?.data?.non_field_errors ||
        err?.response?.data?.data;

        if (errors && (!(errors instanceof Array) || errors.length > 0)) {
          toast.error(errors);
        } else {
          toast.error("Error sending OTP");
        }

        onError && onError();
      }).finally(() => {
        resetTurnstile();
      });
  }

  function resendOtp(shouldSendInWhatsapp) {
    setIsLoading(true);
    setOTP("");

    sendOtp(phoneNumber, selectedCountryCode, shouldSendInWhatsapp).finally(
      () => {
        setIsLoading(false);
      }
    );

    Track.buttonClick(button.resend_otp, trackDetails);
  }

  function defaultOnSuccessfulLoginWorkflow(userData) {
    if (
      SHOW_PROFILE_SETUP &&
      userData?.is_basic_profile_setup_complete === false
    ) {
      LoginModalController().closeContent();
      setShouldUpdateProfile(true);
    } else {
      LoginModalController().closeModal();
    }
  }

  function onSuccessfulLogin(userData) {
    if (loginModalState?.onSuccessfulLogin)
      loginModalState.onSuccessfulLogin(userData);
    else {
      defaultOnSuccessfulLoginWorkflow(userData);
    }
  }

  function verifyOtp(e) {
    e?.preventDefault();

    Track.buttonClick(button.verify_otp, trackDetails);

    setIsLoading(true);

    if (otp.length < 6) {
      toast.error("Please enter 6 digit OTP");
      setIsLoading(false);
      return;
    }

    const payload = {
      mobile: phoneNumber,
      countryCode: selectedCountryCode,
      otp: otp,
      whatsapp_opt_in: isWhatsappOptInEnabled ? true : null,
    };

    if (username) payload.username = username;
    if (isSuperlinkView) payload.source = "superlink";

    let isErrRes = null;

    httpf
      .post(`/api/auth/mobile/validate`, payload)
      .then(async () => {
        await getAuthMe().then((res) => {
            console.log(res.data);
            setUserData({ ...res.data });
            toast.success("OTP verified successfully");
            Track.customEvent("mobile_verified", trackDetails);
            onSuccessfulLogin(res.data);

            return true;
          })
          .catch((err) => {
            Sentry.captureException(err, {
              extra: {
                isAfterLogin: true,
                component: "LoginForm"
              }
            });
            isErrRes = true;

            fetch("/api/auth/logout").finally(() => {
              toast.error("Some error occurred, please login again.");
              setUserData({});
              router.reload();
            });
          });
      })
      .catch((err) => {
        console.log(err);

        if(err?.response?.status !== 400) {
          Sentry.captureException(err);
        }

        isErrRes = true;

        Track.customEvent("mobile_not_verified", trackDetails);
        toast.error("Error verifying OTP! Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const whatsappUpdateCheckbox = (
    <div className="mt-5 flex justify-center items-center">
      <label
        htmlFor="whatsapp-check"
        className="cursor-pointer max-h-[20px] inline-flex items-center text-black/80"
      >
        <input
          type="checkbox"
          id="whatsapp-check"
          className="form-checkbox text-transparent bg-transparent rounded border !border-black/25 focus:ring-offset-0 focus:ring-transparent outline-none focus:outline-none cursor-pointer"
          checked={isWhatsappOptInEnabled}
          onChange={() => {
            setIsWhatsappOptInEnabled(!isWhatsappOptInEnabled);
          }}
        />
        <span className="ml-1.5 text-sm leading-5 select-none">
          Get updates via WhatsApp
        </span>
        <img
          src="/assets/social-icons/whatsapp-original.svg"
          alt=""
          className="ml-1.5 h-4 w-4"
        />
      </label>
    </div>
  );

  const currentInput = !isOTPScreen ? (
    <>
      <PhoneInput
        phoneNumber={phoneNumber}
        placeholder={customConfig?.login_settings?.isWhatsappOptInChecked ? "WhatsApp number" : null}
        _setPhoneNumber={setPhoneNumber}
        initialValue={phoneNumber}
        onSubmit={handleGetOtp}
        handlePhoneNumberBlur={handlePhoneNumberBlur}
        handlePhoneNumberChange={handlePhoneNumberChange}
        selectedCountry={selectedCountry}
        setCountry={setCountry}
        error={error}
      />
      {
        !customConfig?.login_settings?.isWhatsappOptInChecked && (
          whatsappUpdateCheckbox
        )
      }
      <div className="flex flex-row max-w-[334px] sm:max-w-[304px] gap-4 sm:gap-2 mx-auto">
        <GetOtpBtn
          isLoading={isLoadingSMS}
          disabled={!turnstileToken}
          phoneNumber={phoneNumber}
          selectedCountryCode={selectedCountryCode}
          title="Send on SMS"
          color={GetOtpBtn.prototype.color.SMS}
          onClick={() => {
            setShouldSendInWhatsapp(false);
          }}
        />
        <GetOtpBtn
          isLoading={isLoadingWA}
          disabled={!turnstileToken}
          phoneNumber={phoneNumber}
          selectedCountryCode={selectedCountryCode}
          title="Send on WhatsApp"
          color={GetOtpBtn.prototype.color.WHATSAPP}
          onClick={() => {
            setShouldSendInWhatsapp(true);
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex flex-col justify-center items-center">
      <input
        id="single-otp-input"
        className={cls(
          "rounded-sm max-w-[196px] text-2xl font-semibold leading-9 tracking-[0.75em] bg-transparent selection:bg-transparent border-transparent focus:outline-none",
          otp.length > 0 && "mr-[-0.75em]",
          "placeholder-[#3A4045] placeholder-opacity-20 text-[#22272B]"
        )}
        type="number"
        placeholder="••••••"
        pattern="\d*"
        value={otp}
        autoFocus={true}
        autoComplete="off"
        disabled={false}
        onChange={handleOtpChange}
      />
    </div>
  );

  const enterPhoneTitle = customConfig?.login_settings?.heading  || "Enter your phone number";

  return (
    <div className="sm:inline-block sm:align-middle h-screen sm:h-full">
      <div className="relative inline-flex items-end justify-center w-full h-full sm:items-center font-nunito sm:py-14">
        <div className="relative bg-white sm:rounded-sm max-w-full w-full h-full sm:h-[526px] sm:max-w-[344px] sm:w-[344px]">
          {onClose && (
            <img
              src="/assets/icons/cancel.svg"
              lt="close button"
              onClick={onClose}
              className="absolute z-10 cursor-pointer select-none right-5 top-6"
            />
          )}
          <div className="flex flex-col h-full">
            <div className="w-full h-full py-10 px-5 sm:py-6 flex-1">
              {/* Mobile Modal Header */}
              <div className="font-semibold text-[#22272B] leading-6">
                {isOTPScreen ? "Enter 6 digit OTP" : enterPhoneTitle}
              </div>
              {/* Mobile Modal Body */}
              {!isOTPScreen ? (
                <div className="mt-3">
                  <p className="text-[13px] leading-6 text-[#898D90]">
                    We'll send you OTP for verification
                  </p>
                </div>
              ) : (
                <div className="mt-3 flex justify-center items-center text-[#929292]">
                  <p className="text-[13px] leading-6">
                    We sent you OTP on{" "}
                    <button
                      className="inline-flex space-x-1 cursor-pointer hover:text-[#22272B]/80 hover:underline items-center ml-[0.1rem]"
                      onClick={() => {
                        setIsOTPScreen(false);
                        setOTP("");
                        Track.buttonClick(button.edit_phone_number, {
                          ...trackDetails,
                          mobile: phoneNumber,
                          countryCode: selectedCountryCode,
                        });
                      }}
                    >
                      <span className="align-middle whitespace-nowrap">
                        {phoneNumber}
                      </span>
                      <img
                        className="mb-[1px]"
                        src="/assets/icons/edit-block.svg"
                        alt="edit icon"
                      />
                    </button>
                  </p>
                </div>
              )}
              <div className="relative w-full mt-5">{currentInput}</div>
              <Turnstile 
                userRef={turnstileRef}
                retry="auto"
                refreshExpired="auto"
                size="invisible"
                execution="render"
                sitekey={NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
                onVerify={saveTurnstileToken} 
                onError={onTurnstileError}
              />
              {/* Mobile Modal Footer */}
              {isOTPScreen &&
                ((isLoadingSMS || isLoadingWA) ? (
                  <div className="mt-8 flex items-center justify-center">
                    <Spinner className="h-6 w-6"  color="#605FE7" />
                  </div>
                ) : (
                  <ResendOTP resendOtp={resendOtp} />
                ))}
            </div>
            {!isSuperlinkView && (
              <div className="pb-6">
                <PaymentBanner country={country} onAuthModal={true}>
                  {!isOTPScreen && (
                    <p className="text-[#767B80] text-xs leading-5 mt-5">
                      By continuing you agree to our{" "}
                      <a className="font-bold" href="/pages/stdeula" target="_blank">
                        Terms
                      </a>{" "}
                      and{" "}
                      <a className="font-bold" href="/pages/privacy" target="_blank">
                        Privacy policy
                      </a>
                      .
                    </p>
                  )}
                </PaymentBanner>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
