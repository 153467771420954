import cls from "classnames";
import React, { Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";

const Modal = ({ isOpen = false, closeModal, zIndex = "z-10", afterLeave, children, className="" }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef?.current) {
      modalRef.current.scrollTo(0, 0);
    }
  }, [modalRef, children]);

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog as="div" onClose={closeModal} className={className}>
        <div ref={modalRef} className={cls("fixed inset-0 overflow-y-auto", zIndex, className)}>
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="duration-240"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/75" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {children}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

Modal.prototype = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
