import { useEffect, useRef } from "react";
import cls from "classnames";

import CountryCodeSelector from "./CountryCodeSelector";

function PhoneInput({
  phoneNumber,
  placeholder,
  _setPhoneNumber,
  onSubmit,
  handlePhoneNumberChange,
  handlePhoneNumberBlur,
  initialValue = "",
  error,
  selectedCountry,
  setCountry,
}) {
  // const [selectedCountry, setCountry] = useState(INITIAL_SELECTED_COUNTRY);
  // const [error, setError] = useState("");

  const phoneNumberRef = useRef(null);

  useEffect(() => {
    const phoneNumberInput = phoneNumberRef.current;

    if (phoneNumberInput) {
      phoneNumberInput.focus();
    }
  }, [phoneNumberRef.current]);

  // function setPhoneNumber(inputValue = "") {
  //   let phoneNumber = Number(inputValue);

  //   if (!Number.isInteger(phoneNumber)) {
  //     return;
  //   } else if (inputValue === "") {
  //     _setPhoneNumber("");
  //   } else {
  //     _setPhoneNumber(inputValue);
  //   }
  // }

  // function handlePhoneNumberChange(e) {
  //   let phoneNumber = e.target?.value;
  //   const isCountryCodeOfIndia = selectedCountry.code === "91";

  //   if (isCountryCodeOfIndia) {
  //     if (phoneNumber.length <= 10) {
  //       setPhoneNumber(phoneNumber);
  //     }
  //   } else {
  //     if (phoneNumber.length <= 14) {
  //       setPhoneNumber(phoneNumber);
  //     }
  //   }

  //   if (phoneNumber.length > 5) {
  //     setError("");
  //   }
  // }

  // function handlePhoneNumberBlur() {
  //   if (phoneNumber.length > 0 && phoneNumber.length <= 5) {
  //     setError("Enter correct phone number");
  //   } else {
  //     setError("");
  //   }
  // }

  return (
    <form
      id="phone-input-form"
      className={cls(
        "overflow-hidden leading-8 text-xl flex items-center max-h-14 transition px-4 pb-1",
        error ? "!border-[#CF4949]" : "",
        "bg-white border-b border-[#A7A9AA]"
      )}
      onSubmit={onSubmit}
    >
      <CountryCodeSelector
        selectedCountry={selectedCountry}
        setCountry={setCountry}
        className={cls(
          "outline-none flex items-center",
          "text-[#22272B]"
        )}
      />
      <input
        className={cls(
          "w-full border-0 focus:outline-none text-xl leading-8 pl-3",
          "bg-white text-[#22272B] placeholder-[#22272B]/20"
        )}
        ref={phoneNumberRef}
        type="tel"
        value={initialValue || phoneNumber}
        placeholder={placeholder || "9876543210"}
        onChange={handlePhoneNumberChange}
        onBlur={handlePhoneNumberBlur}
        maxLength="15"
      />
    </form>
  );
}

export default PhoneInput;
