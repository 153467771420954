import { isBrowser } from "../../constants/isBrowser";

export function addTagsInRecording(tags) {
  const tagList = Object.entries(tags);

  if (window.clarity && isBrowser) {
    tagList.forEach((tag) => {
      window.clarity("set", tag[0], tag[1]);
    });
  }
}
