import { useEffect } from "react";
import Router from "next/router";
import axios from "axios";

import useSessionStore from "../../store/sessionStore";
import useStore from "../../store/store";
import getAuthMe from "helpers/getAuthMe";

const AuthWrapper = ({ children }) => {
  const setLoaderState = useStore((state) => state.setLoaderState);
  const setLoading = useStore((state) => state.setLoading);
  const userData = useSessionStore((state) => state.userData);
  const setUserData = useSessionStore((state) => state.setUserData);

  useEffect(() => {
    setLoading(true);
    getAuthMe().then((res) => {
        setUserData({ ...res.data });
      })
      .catch((err) => {
        if (userData.uid) {
          setLoaderState(true);
          fetch("/api/auth/logout").finally(() => {
            setUserData({});
            setLoaderState(false);
            Router.reload();
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return <>{children}</>;
};

export default AuthWrapper;
