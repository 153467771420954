import axios from "axios";
import useSessionStore from "../../store/sessionStore";
import store from "../../store/store";

const httpf = axios.create();

httpf.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        useSessionStore.getState().setUserData({});
        store.getState().LoginModalController().openModal();

        return Promise.reject(err);
      }

      if (err?.response?.status === 400) {
        const e = new Error(err?.response?.data?.message);
        e.name = err?.response?.data?.type;
        e.response = err.response;

        throw e;
      }
    }

    return Promise.reject(err);
  }
);

export default httpf;
