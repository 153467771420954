import Script from "next/script";
import { useEffect } from "react";

export default function GoogleAnalytics() {
  const trackingID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

  const initializeGA = () => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    gtag("js", new Date());
    gtag("config", trackingID, {
      page_path: window.location.pathname,
    });
  };

  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingID}`}
      />
    </>
  );
}
