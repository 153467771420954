import {combinePatterns} from '../utils/string';

// https://www.ietf.org/rfc/rfc3986.txt
// https://blog.codinghorror.com/the-problem-with-urls/
// http://www.regular-expressions.info/email.html

export const VALID_PATH_CHARS =
  /(?:[a-zA-Z\u0400-\u04FF0-9\-_~!$&'()[\]\\/*+,;=.%]*)/;

export const MAIL_SCHEME =
  /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;

export const URL_SCHEME = /(https?:\/\/)?/;

export const URL_AUTH = combinePatterns(
  [
    /[a-z\u0400-\u04FF0-9\-_~!$&'()*+,;=.:]+/, // Includes colon
    /@/,
  ],
  {
    capture: true,
    match: "?",
  }
);

export const URL_HOST = combinePatterns(
  [
    /(?:(?:[a-z0-9](?:[-a-z0-9_]*[a-z0-9])?)\.)*/, // Subdomain
    /(?:(?:[a-z0-9](?:[-a-z0-9]*[a-z0-9])?)\.)/, // Domain
    /(?:[a-z](?:[-a-z0-9]*[a-z0-9])?)/, // TLD
  ],
  {
    capture: true,
  }
);

export const URL_PORT = /(?::(\d{1,5}))?/;

export const URL_PATH = combinePatterns(
  [
    /\//,
    combinePatterns(
      [
        /[-+a-z0-9!*';:=,.$/%[\]_~@|&]*/,
        /[-+a-z0-9/]/, // Valid ending chars
      ],
      {
        match: "*",
        nonCapture: true,
      }
    ),
  ],
  {
    capture: true,
    match: "?",
  }
);

export const URL_QUERY = combinePatterns(
  [
    /\?/,
    combinePatterns(
      [
        VALID_PATH_CHARS,
        /[a-z0-9_&=]/, // Valid ending chars
      ],
      {
        match: "?",
        nonCapture: true,
      }
    ),
  ],
  {
    capture: true,
    match: "?",
  }
);

export const URL_FRAGMENT = combinePatterns(
  [
    /#/,
    combinePatterns(
      [
        VALID_PATH_CHARS,
        /[a-z0-9]/, // Valid ending chars
      ],
      {
        match: "?",
        nonCapture: true,
      }
    ),
  ],
  {
    capture: true,
    match: "?",
  }
);

export const URL_PATTERN = combinePatterns(
  [URL_AUTH, URL_HOST, URL_PORT, URL_PATH, URL_QUERY, URL_FRAGMENT],
  {
    flags: "gi",
  }
);