const SearchSvg = ({ color, opacity, ...props }) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={opacity}
      stroke={color}
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        opacity={opacity}
        d="M8.167 14.833a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333ZM16.5 16.5l-3.625-3.625"
      />
    </g>
  </svg>
);

export default SearchSvg;
