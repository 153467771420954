import * as Sentry from "@sentry/nextjs";
import * as Track from "./analytics/track";
import { hotjar } from "react-hotjar";
import { pageview } from "../constants/analytics";
import { getImageDimensions, getUrlDimensions } from "./file";
import { delay } from "./functions";
import { _waEmbed } from "@qoohoo/common/library/whatsappSupportButton";

export const isBrowser = typeof window !== "undefined";

export const sentrySetUser = (uid, username) => {
  const userId = uid || "anonymous";
  const userName = username || "nil";

  Sentry.setUser({
    id: userId,
    username: userName,
  });
};

export const setFreshchatUser = (userData) => {
  const { uid, username, isCreator, email, mobile: phone } = userData;
  if (window.fcWidget && isBrowser) {
    window.fcWidget.setExternalId(uid);

    window.fcWidget.user.setFirstName(username);

    window.fcWidget.user.setProperties({
      uid,
      username,
      isCreator,
      email,
      phone,
    });
  }
};

export const addSupportChat = async (maxCount = 120, interval = 500) => {
  if (!isBrowser) return;

  const whatsappBtn = document.getElementById("wa-btn-wrapper");

  if (!whatsappBtn) {
    const pageUrl = window?.location?.href || "";

    const wa_btnSetting = {
      btnColor: "#16BE45",
      cornerRadius: 40,
      marginBottom: 20,
      marginLeft: 20,
      marginRight: 20,
      btnPosition: "right",
      zIndex: 999999,
      btnColorScheme: "light",
      whatsAppNumber: "917669359471",
      welcomeMessage: `Hi qoohoo team, I have a question. ${pageUrl}`,
      ctaText: "Chat with us",
    };

    _waEmbed(wa_btnSetting);
  }
};

export const removeSupportChat = async (maxCount = 120, interval = 500) => {
  if (!isBrowser) return;

  for (let i = 0; i < maxCount; ++i) {
    const whatsappBtn = document.getElementById("wa-btn-wrapper");

    if (whatsappBtn) {
      whatsappBtn.style.display = "none";
      break;
    } else {
      await delay(interval);
    }
  }
};

export const hotjarInit = (uid, username) => {
  const hjid = process.env.NEXT_PUBLIC_HOTJAR_ID;
  const hjsv = process.env.NEXT_PUBLIC_HOTJAR_VERSION;

  hotjar.initialize(hjid, hjsv);

  const userId = uid || "anonymous";
  const userName = username || "anonymous";

  hotjar.identify(userId, { username: userName });
};

export const getPageNameByUrl = (url) => {
  if (url.includes("/channels/")) {
    return pageview.channel_details;
  }

  if (url.includes("/courses/")) {
    return pageview.courses;
  }

  if (url.includes("/book-slots/")) {
    return pageview.one_to_one;
  }

  if (url.includes("/premium-content/")) {
    return pageview.premium_content;
  }

  if (url.includes("/by/")) {
    return pageview.profile;
  }

  return url;
};

export const trackIfWeirdImageDimensions = async (source) => {
  let dimensions;

  try {
    if (source instanceof File || source instanceof Blob) {
      dimensions = await getImageDimensions(source);
    } else {
      dimensions = await getUrlDimensions(source);
    }
  } catch (error) {
    console.error(error);
    return;
  }

  const thresholdRatio = 2.0;
  const { height, width } = dimensions;

  if (height / width > thresholdRatio || width / height > thresholdRatio) {
    Track.customEvent("UNEXPECTED_IMG_DIMENSIONS", {
      height,
      width,
    });
  }
};
