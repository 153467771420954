export function validatePANNumber(value) {
  if (!value) {
    return false;
  }

  let isValid;
  const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  isValid = regex.test(value) ? true : false;

  return isValid;
}

export function validateAadhaarNumber(value) {
  if (!value) {
    return false;
  }

  let isValid;
  const regex = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/;

  let _value = '';
  value.replace(/\s/g,'').split('').forEach((c, index) => {

    if (index && (index) % 4 === 0) {
      _value += ' ';
    }

    _value += c;
  });

  isValid = regex.test(_value);

  return isValid;
}

export function validateEmail(value) {
  const regex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  const isValid = regex.test(value);

  return isValid;
}

export function validateName(value) {
  const regex = /^([a-zA-Z ]){2,30}$/;
  const isValid = regex.test(value);

  return isValid;
}

export function validateMultiInput(value, validator) {
  let isValid = true;

  if(value) {
    const values = value.split(",");
    
    for(const _value of values) {
      if(!validator(_value)) {
        isValid = false;
        break;
      }
    }
  }

  return isValid;
}