const STANDARD_EVENTS = {
  AddPaymentInfo: "AddPaymentInfo", // done
  AddToCart: "AddToCart", // done
  AddToWishlist: "AddToWishlist",
  CompleteRegistration: "CompleteRegistration",
  Contact: "Contact",
  CustomizeProduct: "CustomizeProduct",
  Donate: "Donate",
  FindLocation: "FindLocation",
  InitiateCheckout: "InitiateCheckout", // done
  Lead: "Lead", // done
  PageView: "PageView",
  Purchase: "Purchase", // done
  Schedule: "Schedule",
  Search: "Search",
  StartTrial: "StartTrial",
  SubmitApplication: "SubmitApplication",
  Subscribe: "Subscribe", // done
  ViewContent: "ViewContent",
};

const isFBQAvailable =
  typeof window !== "undefined" && typeof window.fbq === "function";

function trackStandardEvent(eventName, content_ids, contents, _value) {
  if (eventName in STANDARD_EVENTS && isFBQAvailable) {
    const value = _value || 0;

    window.fbq("track", eventName, {
      currency: "INR",
      content_type: "product",
      content_ids,
      contents,
      value,
    });
  }
}

function trackCustomEvent(eventName, eventData) {
  if (isFBQAvailable) {
    window.fbq("trackCustom", eventName, eventData);
  }
}

function sendShareEventViaPixel(eventData) {
  trackCustomEvent("Share", eventData);
}

export {
  trackStandardEvent,
  STANDARD_EVENTS,
  trackCustomEvent,
  sendShareEventViaPixel,
};
