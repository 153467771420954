function emojiSupport() {
  if (emojiSupport.cache !== null) {
    return emojiSupport.cache;
  }

  if (typeof window === "undefined") {
    return false;
  }

  const canvas = document.createElement("canvas");
  canvas.height = 10;
  canvas.width = canvas.height*2;
  
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return false;
  }

  ctx.font = canvas.height+"px Arial";
  ctx.fillText("🇮🇳", 0, canvas.height);
  
  const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
  let i = 0;

  let isSupported = false;

  while(i<data.length) {
      if (data[i] !== data[i+1] || data[i] !== data[i+2]) {
        isSupported = true;
        break;
      }

      i+=4;
  }

  emojiSupport.cache = isSupported;

  return isSupported;
}

emojiSupport.cache = null;

export default emojiSupport;