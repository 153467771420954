import Resizer from "react-image-file-resizer";

export const resizeFile = (file, maxWidth, maxHeight, quality) => {
    
    return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}

export const getUrlDimensions = (url) => {
  const img = document.createElement("img");

  const promise =  new Promise((resolve, reject) => {
      img.onload = () => {
        const width  = img.naturalWidth;
        const height = img.naturalHeight; 

        resolve({width, height});
      };

      img.onerror = reject;
    });

  img.src = url;

  return promise;
}

export const getImageDimensions = (file) => {
  const objectUrl = URL.createObjectURL(file);

  return getUrlDimensions(objectUrl);
}