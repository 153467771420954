import React from "react";
import Head from "next/head";
import nProgress from "nprogress";
import { useEffect } from "react";
import { Router, useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import * as Sentry from "@sentry/nextjs";
import InApp from "detect-inapp";
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import Portals from "../components/Portals";
import AuthWrapper from "../components/auth/AuthWrapper";
import GoogleAnalytics from "../components/GoogleAnalytics";
import AmplitudeAnlaytics from "../components/AmplitudeAnalytics";

import * as Track from "@qoohoo/common/utils/analytics/track";
import * as Posthog from "@qoohoo/common/utils/analytics/posthog";
import { INTERCOM_APP_ID } from "constants/constants";
import getSEOConfig from "../../next-seo.config";
import useSessionStore from "../store/sessionStore";
import { getIsIOS } from "@qoohoo/common/utils/dom";
import {sentrySetUser, setFreshchatUser} from "@qoohoo/common/utils/analytics";
import {bootIntercom, hideIntercom, loadIntercom, showIntercom, shutdownIntercom, updateIntercom} from "@qoohoo/common/utils/intercom";
import useHotjar from "../hooks/useHotjar";
import { STANDARD_EVENTS, trackStandardEvent } from "@qoohoo/common/utils/analytics/pixel";
import { addTagsInRecording } from "@qoohoo/common/utils/analytics/clarity";

import "../styles/globals.css";
import "../styles/nprogress.css";
import "../styles/react-calendar.css";
import "tailwindcss/tailwind.css";
import "../styles/tailwindGlobals.css";
import "@qoohoo/common/styles/animations.css";
import "@qoohoo/common/styles/tooltip.css";
import {getSlugs} from "@qoohoo/common/utils/url";

import "../styles/animation.css";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

Router.events.on("routeChangeError", (err, url) => {
  const wasNavigationCancelled = err.cancelled;

  if(!wasNavigationCancelled) {
    Sentry.captureException(err, url);
  }
});

  // Check that PostHog is client-side (used to handle Next.js SSR)
  if (typeof window !== "undefined") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
      // Enable debug mode in development
      loaded: (posthog) => {
        console.log("Posthog loaded successfully");
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });
  }

function checkIfDashboardRoute(router) {
  const slugs = getSlugs(router.pathname);
  const isDashboardRoute = slugs[0] === 'dashboard';

  return isDashboardRoute;
}


export function withMultiEntry(AppBase) {
  const _MyApp = function (props) {
    const router = useRouter();

    let _AuthWrapper = AuthWrapper;

    const isDashboardRoute = checkIfDashboardRoute(router);

    if (isDashboardRoute) {
    }

    return <AppBase {...props} _AuthWrapper={_AuthWrapper} />;
  };

  return _MyApp;
}

function MyApp({ Component, pageProps, _AuthWrapper }) {
  const userData = useSessionStore((state) => state.userData);

  const router = useRouter();
  const isDashboardRoute = checkIfDashboardRoute(router);

  const SEOConfig = isDashboardRoute ? null : getSEOConfig(router.route);

  function allowIntercomButton(router) {
    try {
      const slugs = getSlugs(router.pathname);
  
      const initialSlug = slugs[0];
      const creatorPages = [
        'channels', 'courses', 'premium-content', 'booking-details', 'session', 'by',
        'complete-kyc', '[username]', 'book-slots'
      ];
  
      const isCreatorPage = creatorPages.indexOf(initialSlug) > -1;
  
      return !isCreatorPage;
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const inapp = new InApp(
      navigator.userAgent || navigator.vendor || window.opera
    );
    const browserDetails = {
      isIOS: getIsIOS(),
      isWebview: inapp.isInApp,
    };

    addTagsInRecording(browserDetails);

    Track.addProperties(browserDetails);
    Posthog.addProperties(browserDetails);

    if (userData?.username) {
      const userDetails = {
        user_id: userData?.uid,
        username: userData?.username,
        is_user_creator: userData?.isCreator,
      };

      addTagsInRecording(userDetails);

      setFreshchatUser(userData);

      Track.addProperties(userDetails);
      Posthog.addProperties(userDetails);
  }

    return () => {
      Track.clearProperties();
      Posthog.removeUniqueUserID();
    };
  }, [userData.username]);

  useEffect(() => {
    sentrySetUser(userData?.uid, userData?.username);
  }, [userData?.uid]);

  useEffect(() => {
    trackStandardEvent(STANDARD_EVENTS.PageView);
  }, []);

  useEffect(() => {
    // Boot Intercom on initial mount
    bootIntercom(INTERCOM_APP_ID);
  }, []);

  useEffect(() => {
    const isLoggedIn = userData?.uid; 

    if(isLoggedIn) {
      updateIntercom({
        name: userData.name,
        email: userData.email,
        user_id: userData.uid,
        phone: userData.mobile,
        user_hash: userData.intercom_user_hash,
        is_creator: !!userData.isCreator
      });
    } else {
      // shutdownIntercom() is used to clear existing user session details, so chats are cleared once user logs out
      shutdownIntercom();
      bootIntercom(INTERCOM_APP_ID);
    }
  }, [userData?.uid]);

  useEffect(() => {
    const handleRouteChange = () => {
      if(allowIntercomButton(router)) {
        showIntercom();
      } else {
        hideIntercom();
      }
    };

    // Call once so it hides or shows on initial mount
    handleRouteChange();

    router.events.on('routeChangeComplete', handleRouteChange);
    
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  let _ParentComponent;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    _ParentComponent = AmplitudeAnlaytics;
  } else {
    _ParentComponent = React.Fragment;
  }

  return (
    <_ParentComponent>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
        />
      </Head>
      {SEOConfig && <DefaultSeo {...SEOConfig} />}
      {/* // Remove GA to stop multiple tracking of the same events */}
      {/* <GoogleAnalytics /> */}
      <_AuthWrapper>
        <PostHogProvider client={posthog}>
          <Component {...pageProps} />
          <Portals />
        </PostHogProvider>
      </_AuthWrapper>
    </_ParentComponent>
  );
}

export default withMultiEntry(MyApp);
