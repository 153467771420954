const pageview = {
  about: "about_page",
  career: "career_page",
  pricing: "pricing_page",
  creators_guide: "creators_guide_page",
  community_guidelines: "community_guidelines_page",
  faq: "faq_page",
  privacy_policy: "privacy_policy_page",
  terms_conditions: "terms_conditions_page",
  refund_cancellation: "refund_cancellation_page",
  404: "404_page",
  500: "500_page",
  download: "download_page",
  download_android: "download_android_page",
  home: "home_page",
  profile: "profile_page",
  channel_details: "channel_details_page",
  courses: "courses_page",
  get_the_app: "get_the_app_page",
  release_notes: "release_notes_page",
  payout_policy: "payout_policy_page",
  collections: "collections_page",
  learn_how_to_monetize: "learn_how_to_monetize_page",
  premium_content: "premium_content_page",
  saved_content: "saved_content",
  one_to_one: "one_to_one",
  rise: "rise_page",
  product_page_telegram: "product_page_telegram",
  code_code_policy: "code_code_policy",
  referral_policy: "referral_policy",
  superlink: "superlink",
};

const link = {
  qoohoo_logo: "qoohoo_logo_link",
  about: "about_link",
  career: "career_link",
  blog: "blog_link",
  creators_guide: "creators_guide_link",
  community_guidelines: "community_guidelines_link",
  support: "support_link",
  faq: "faq_link",
  refund_cancellation: "refund_cancellation_link",
  qoohoo_facebook: "qoohoo_facebook_link",
  qoohoo_instagram: "qoohoo_instagram_link",
  qoohoo_twitter: "qoohoo_twitter_link",
  qoohoo_youtube: "qoohoo_youtube_link",
  qoohoo_linkedin: "qoohoo_linkedin_link",
  privacy_policy: "privacy_policy_link",
  terms_conditions: "terms_conditions_link",
  pricing: "pricing_link",
  open_in_app: "open_in_app_link",
  open_in_browser: "open_in_browser_link",
  share_via_whatsapp: "share_via_whatsapp",
  share_via_facebook: "share_via_facebook",
  share_via_twitter: "share_via_twitter",
  share_via_linkedin: "share_via_linkedin",
  share_via_instagram: "share_via_instagram",
  share_via_telegram: "share_via_telegram",
  creator_profile_link: "creator_profile_link",
  channel_card_link: "channel_card_link",
  join_on_telegram_link: "join_on_telegram_link",
  join_on_whatsapp_link: "join_on_whatsapp_link",
  telegram_payments_product_page: "telegram_payments_product_page",
  whatsapp_payments_product_page: "whatsapp_payments_product_page",
  monetise_youtube_videos_product_page: "monetise_youtube_videos_product_page",
};

const button = {
  accept_payment_consent_modal: "accept_payment_consent_modal",
  close_payment_consent_modal: "close_payment_consent_modal",
  go_to_home: "go_to_home_btn",
  download_app: "download_app_btn",
  download: "download_btn",
  book_a_demo: "book_a_demo",
  cta_btn: "cta_btn",
  become_creator: "become_creator_btn",
  contact_us: "contact_us",
  testimonial_video: "testimonial_video_btn",
  profile_tab: "profile_tab_btn",
  description_show_more: "description_show_more_btn",
  tab_show_more: "tab_show_more_btn",
  support_my_passion: "support_my_passion_btn",
  share: "share_btn",
  copy_link: "copy_link_btn",
  join_channel: "join_channel_btn",
  go_to_channel: "go_to_channel_btn",
  pay: "pay_btn",
  login: "login",
  logout: "logout",
  auth_modal: "auth_modal",
  scroll_to_creator: "scroll_to_creator_btn",
  send_otp: "send_otp_btn",
  verify_otp: "verify_otp_btn",
  verify_email: "verify_email_btn",
  next_review: "next_review_btn",
  prev_review: "prev_review_btn",
  next_intro_media: "next_intro_media_btn",
  prev_intro_media: "prev_intro_media_btn",
  click_intro_media: "click_intro_media_btn",
  faq: "faq_btn",
  subscription_plan_select: "subscription_plan_select_btn",
  google_login: "google_login_btn",
  resend_otp: "resend_otp_btn",
  resend_email: "resend_verification_email_btn",
  edit_phone_number: "edit_phone_number_btn",
  login_to_continue: "login_to_continue_btn",
  unlock_premium_content: "unlock_premium_content_btn",
  share_options: "share_options_btn",
  post_see_more: "post_see_more_btn",
  next_premium_post: "next_premium_post_btn",
  prev_premium_post: "prev_premium_post_btn",
  click_premium_post: "click_premium_post_btn",
  click_premium_post_preview: "click_premium_post_preview_btn",
  play_audio: "play_audio_btn",
  pause_audio: "pause_audio_btn",
  download_file: "download_file_btn",
  thank_you: "thank_you_btn",
};

const api = {
  payment_consent_api: "payment_consent_api",
  custom_user_form_api: "custom_user_form_api",
}

export { pageview, link, button, api };
