import {DASHBOARD_DOMAIN} from "@qoohoo/common/constants/env";

export * from "@qoohoo/common/helpers/url-pattern";

export const cacheAge = 60 * 60 * 24 * 2; // 2 days

export const appStoreUrl = "https://apps.apple.com/ai/app/qoohoo/id1554639726";

export const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.qoohoo_app";

// https://www.ietf.org/rfc/rfc3986.txt
// https://blog.codinghorror.com/the-problem-with-urls/
// http://www.regular-expressions.info/email.html

export const ONLY_POSITIVE_INTEGER = /^[0-9]\d*$/gm;
export const VALID_ALNUM_CHARS = /[a-z0-9]/;

export const INITIAL_SELECTED_COUNTRY = {
  phone_code: "91",
  code: "IN",
  name: "India",
};

export const VALID_PAN= /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

export const enableNewCheckoutForFeature = {
  whatsapp_channel: true,
  telegram_channel: true,
  regular_channel: true,
  course: true,
  one_to_one_meeting: true,
  premium_content: true,
};

export const creatorOnboardingUrl = `${DASHBOARD_DOMAIN}/creator-onboarding`;
export const creatorLoginUrl = `${DASHBOARD_DOMAIN}/login`;

export const NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY;
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;