import cls from "classnames";

const Spinner = ({ className, color = "#ffffff", size }) => {
  const variant = {
    xs: "h-6 w-6",
    sm: "h-8 w-8",
    md: "h-10 w-10",
    lg: "h-14 w-14",
  };

  const sizeClass = variant[size] || "";

  return (
    <span
      className={cls(className, sizeClass, "Spinner Spinner--show")}
      style={{
        borderColor: `${color}33`, // 33 -> .20 opacity
        borderTopColor: `${color}`,
        aspectRatio: 1 / 1,
      }}
    />
  );
};

export default Spinner;
