import Category from "./Category";

const Categories = ({ categories, value, onChange }) => {
  const handleOnChange = (value) => {
    onChange(value);
  };

  return (
    <div className="flex items-center flex-wrap gap-2">
      {categories.map((category) => (
        <Category
          category={category}
          key={category.uid}
          isSelected={category.uid === value?.uid}
          select={(e) => {
            e.preventDefault();
            handleOnChange(category);
          }}
        />
      ))}
    </div>
  );
};

export default Categories;
