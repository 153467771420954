import emojiSupport from "../utils/emoji-support";

const DEFAULT_CDN_URL =
  "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/";
const DEFAULT_CDN_SUFFIX = "svg";
// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397;

export default function CountryFlag({ countryCode, ...props }) {
  function checkEmojiSupport() {
    const isEmojiSupported = emojiSupport();
    return isEmojiSupported;
  }

  const isEmojiSupported = checkEmojiSupport();

  let flag = null;

  if (isEmojiSupported) {
    const emoji = countryCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + OFFSET)
      );

    flag = (
      <span role="img" {...props}>
        {emoji}
      </span>
    );
  } else {
    const flagUrl = `${DEFAULT_CDN_URL}${countryCode.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

    flag = <img {...props} src={flagUrl} />;
  }

  return <> {flag} </>;
}
