import { getNewTimer_Exponential } from "./number";

export const debounce = (func, delay) => {
  let timer;
  return function () {
    clearTimeout(timer);

    timer = setTimeout(() => func.apply(this, arguments), delay);
  };
};

export const throttle = (func, delay) => {
  let timer;
  return function () {
    if (timer) {
      return;
    }

    func.apply(this, arguments);

    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
    }, delay);
  };
};

export function longPolling( callback, maxRetries = 8, timerFn) {
  const MAX_RETRIES = maxRetries;
  return new Promise((resolve, reject) => {
    let pollingAttempt = 1;
    let nextExecutionDelay = timerFn ? timerFn(pollingAttempt) : getNewTimer_Exponential(pollingAttempt);
    let executeTimer;
    let isApiInProgress = false;

    function scheduleNextExecution() {
      if (!isApiInProgress) {
        execute();
      }

      pollingAttempt++;
      nextExecutionDelay = timerFn ? timerFn(pollingAttempt) : getNewTimer_Exponential(pollingAttempt) - nextExecutionDelay;

      executeTimer = setTimeout(scheduleNextExecution, nextExecutionDelay);
    }

    setTimeout(scheduleNextExecution, nextExecutionDelay);

    async function execute() {
      isApiInProgress = true;

      const res = await callback();
      const isApiSuccess = res;

      if (isApiSuccess) {
        clearTimeout(executeTimer);
        executeTimer = null;

        resolve(res);
      } else {
        const isMaxAttemptsExhausted = (pollingAttempt === MAX_RETRIES);

        if (isMaxAttemptsExhausted) {
          clearTimeout(executeTimer);

          nextExecutionDelay = null;
          executeTimer = null;

          reject("Failed");
        } else {
          isApiInProgress = false;
        }
      }
    }
  });
}

export function delay(milliseconds) {
  return new Promise((res)=>{
    setTimeout(res, milliseconds);
  });
}

// polls cb until it returns truthy value 
export function linearPolling(cb, interval = 150, max_retries = 20) {
  return longPolling(cb, max_retries, () => interval);
}