import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import toast from "react-hot-toast";

import CategoryDescription from "../../../../../dashboard/src/components/superlink/onboarding/CategoryDescription";
import Modal from "@qoohoo/common/library/modals/Modal";

import httpf from "../../../utils/frontend/httpf";
import useStore from "../../../store/store";
import useSessionStore from "../../../store/sessionStore";

export const SuperlinkOnboardingForm = ({ onClose }) => {
  const router = useRouter();

  const [name, setName] = useState("");
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  const userData = useSessionStore((state) => state.userData);

  const superlinkOnboardingModalState = useStore(
    (state) => state.superlinkOnboardingModalState
  );

  useEffect(() => {
    if (userData?.uid) {
      setName(userData.name);
      httpf
        .get(`/api/get-categories`, {
          params: {
            type: "creator"
          }
        })
        .then((res) => {
          setCategories(res.data);
        })
        .catch((err) => {});
    }
  }, [userData]);

  const handleNameChange = (ev) => {
    let value = ev.target.value;

    // preventing special characters
    value = value.replace(/[^a-zA-Z ]+/gi, "");
    // preventing more than one space
    value = value.replace(/\s\s+/gi, " ");

    setName(value);
  };

  const sortedCategories =
    categories && Object.values(categories).sort((a, b) => a?.order - b?.order);

  const nextClickHandler = async () => {
    if (!name) return toast.error("Please fill in your name");
    if (!selectedCategory) return toast.error("Please select a category");

    const payload = {
      user_details: {
        fullname: name,
        creator_categories: [selectedCategory.uid],
        use_new_profile_page: true,
      },
    };

    try {
      await httpf.post(`/api/update-profile`, payload);
      toast.success("Successfully updated info");
      if (superlinkOnboardingModalState?.onSuccessfulOnboarding)
        superlinkOnboardingModalState.onSuccessfulOnboarding();
      else onClose();
    } catch (error) {
      toast.error("Sorry, something went wrong!");
      onClose();
    }
  };

  return (
    <div className="sm:inline-block sm:align-middle h-screen sm:h-full">
      <div className="relative inline-flex items-end justify-center w-full h-full sm:items-center font-nunito sm:py-14">
        <div className="relative bg-white sm:rounded-sm max-w-full w-full h-full sm:h-[526px] sm:max-w-[360px] sm:w-[360px] text-black overflow-y-scroll">
          {onClose && (
            <img
              src="/assets/icons/cancel.svg"
              lt="close button"
              onClick={onClose}
              className="absolute z-10 cursor-pointer select-none right-5 top-6"
            />
          )}
          <form className="mt-[48px] w-full text-left pt-10 px-5 sm:pt-6">
            <label
              htmlFor="name"
              className="font-nunito font-semibold text-base"
            >
              Tell us your name
            </label>
            <input
              id="name"
              spellCheck={false}
              type="text"
              placeholder="Johny Bravo"
              value={name}
              onChange={handleNameChange}
              className="w-full mt-1 py-2 border-b border-[#97999A] placeholder-[#BEBFC0] placeholder-shown:text-[20px] placeholder-shown:font-nunito text-[20px] leading-[32px] outline-none focus:border-black"
            />
          </form>
          <div className="my-[48px]">
            <CategoryDescription
              categories={sortedCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
          <button
            onClick={nextClickHandler}
            className="sticky bottom-[68px] sm:bottom-9 ml-auto mr-[10px] bg-[#605FE7] text-white font-nunito font-bold flex items-center gap-2 py-3 px-7 rounded-full"
          >
            <span>Next</span>
            <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuperlinkOnboardingForm;
