import Categories from "./Categories";

const CategoryDescription = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <div className="w-full text-left px-4">
      <h2 className="font-nunito font-semibold text-base">
        Tell us your category that will best describe your profile
      </h2>

      <div className="mt-3 flex flex-col gap-4">
        {categories?.map((category) => (
          <div key={category.order} className="flex flex-col gap-3">
            <h3 className="font-nunito text-[13px] leading-[18px]">
              {category.name}
            </h3>
            <Categories
              categories={category.categories}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryDescription;
