export function roundMemberCountForCreator(memberCount) {
  let result = memberCount || 0;

  if (memberCount >= 1000000) {
    const memberCountQuotient = Math.floor(memberCount / 1000000);

    result = memberCountQuotient + "m";
  } else if (memberCount >= 1000) {
    const memberCountQuotient = Math.floor(memberCount / 1000);

    result = memberCountQuotient + "k";
  } else if (memberCount > 100) {
    const memberCountQuotient = Math.floor(memberCount / 50);

    if (memberCount % 50 === 0) {
      result = (memberCountQuotient - 1) * 50;
    } else {
      result = memberCountQuotient * 50;
    }
  } else if (memberCount > 30) {
    const memberCountQuotient = Math.floor(memberCount / 10);

    if (memberCount % 10 === 0) {
      result = (memberCountQuotient - 1) * 10;
    } else {
      result = memberCountQuotient * 10;
    }
  } else if (memberCount > 5) {
    const memberCountQuotient = Math.floor(memberCount / 5);

    if (memberCount % 5 === 0) {
      result = (memberCountQuotient - 1) * 5;
    } else {
      result = memberCountQuotient * 5;
    }
  }

  return result;
}

export function getNewTimer_Linear(interval = 5) {
  const newTimer = interval * 1000;

  return newTimer;
}

export function getNewTimer_Exponential(index) {
  const newTimer = parseInt(Math.pow(1.8, index)) * 1000;

  return newTimer;
}

export function getRoundedMembersCount(totalMembers) {
  let roundedMembersCount = 0;

  if (totalMembers >= 1000000000) {
    const count = totalMembers / 1000000000;
    roundedMembersCount = `${Math.floor(count)}B+`;
  } else if (totalMembers >= 500000000) {
    roundedMembersCount = "500M+";
  } else if (totalMembers >= 1000000) {
    roundedMembersCount = "1M+";
  } else if (totalMembers >= 500000) {
    roundedMembersCount = "500K+";
  } else if (totalMembers >= 100000) {
    roundedMembersCount = "100K+";
  } else if (totalMembers >= 10000) {
    roundedMembersCount = "10K+";
  } else if (totalMembers >= 1000) {
    roundedMembersCount = "1K+";
  } else if (totalMembers > 100 && totalMembers <= 999) {
    const count = totalMembers;

    const middleDigit = Number.parseInt(count.toString()[1]);
    const firstDigit = count.toString()[0];

    let memberCount;

    if (middleDigit >= 5) {
      memberCount = `${firstDigit}50+`;
    } else if (middleDigit < 5) {
      memberCount = `${firstDigit}00+`;
    }

    roundedMembersCount = memberCount;
  } else if (totalMembers > 90 && totalMembers <= 100) {
    roundedMembersCount = "90+";
  } else if (totalMembers > 80 && totalMembers <= 90) {
    roundedMembersCount = "80+";
  } else if (totalMembers > 70 && totalMembers <= 80) {
    roundedMembersCount = "70+";
  } else if (totalMembers > 60 && totalMembers <= 70) {
    roundedMembersCount = "60+";
  } else if (totalMembers > 50 && totalMembers <= 60) {
    roundedMembersCount = "50+";
  } else if (totalMembers > 40 && totalMembers <= 50) {
    roundedMembersCount = "40+";
  } else if (totalMembers > 30 && totalMembers <= 40) {
    roundedMembersCount = "30+";
  } else if (totalMembers > 25 && totalMembers <= 30) {
    roundedMembersCount = "25+";
  } else if (totalMembers > 20 && totalMembers <= 25) {
    roundedMembersCount = "20+";
  } else if (totalMembers > 15 && totalMembers <= 15) {
    roundedMembersCount = "15+";
  } else if (totalMembers > 10 && totalMembers <= 15) {
    roundedMembersCount = "10+";
  } else if (totalMembers > 5 && totalMembers <= 10) {
    roundedMembersCount = "5+";
  } else {
    roundedMembersCount = totalMembers;
  }

  return roundedMembersCount;
}

export function isReallyNumber(data) {
  return typeof data === "string" && data !== "" && !isNaN(data);
}
