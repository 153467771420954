import { useState, useEffect } from "react";
import axios from "axios";
import cls from "classnames";
import Router from "next/router";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";

import Spinner from "@qoohoo/common/library/loader/Spinner";

import httpf from "../../../utils/frontend/httpf";
import useSessionStore from "../../../store/sessionStore";
import useStore from "../../../store/store";
import * as Track from "@qoohoo/common/utils/analytics/track";
import PaymentBanner from "../../premium-content/PaymentBanner";
import ModalWrapper from "../../channels/modals/login/ModalWrapper";
import {validateEmail} from "@qoohoo/common/utils/validators";
import getAuthMe from "helpers/getAuthMe";

function ProfileUpdateForm({ closeModal, closeOnSuccess=true, customSettings={} }) {
  const userData = useSessionStore((state) => state.userData);

  const [fullname, setFullname] = useState(userData.name || "");
  const [email, setEmail] = useState(userData.email || "");
  const [loading, setLoading] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const showEmailField = customSettings?.login_settings?.user_form?.email;

  const setUserData = useSessionStore((state) => state.setUserData);

  const setShouldUpdateProfile = useStore(
    (state) => state.setShouldUpdateProfile
  );

  const LoginModalController = useStore(
    (state) => state.LoginModalController
  );

  const country = useStore((state) => state.country);

  const trackDetails = {
    fullname,
    email,
    ...userData,
  };

  useEffect(() => {
    toggleSubmitButton()
  }, [fullname, email])

  function handleFullnameChange(e) {
    const newValue = e.target.value?.replace(/\s\s+/g, " ");
    setFullname(newValue);
  }

  function handleEmailChange(e) {
    const newValue = e.target.value?.replace(/\s/g, " ");
    setEmail(newValue);
  }

  function toggleSubmitButton() {
    let isFormValid;

    // 1. Validate fullname
    isFormValid = fullname?.trim()?.length > 0;

    // 2. Validate email if present
    if (showEmailField) {
      isFormValid = validateEmail(email);
    }

    setIsSubmitEnabled(isFormValid);
  }

  function updateProfile(payload, onSuccess) {
    httpf
      .post(`/api/auth/mobile/profile-update`, payload)
      .then(async () => {
        await getAuthMe().then((res) => {
            toast.success("Profile created successfully.");
            Track.customEvent("mobile_verified", trackDetails);
            setUserData({ ...res.data });

            if (typeof onSuccess === "function") {
              onSuccess();
            }
          })
          .catch((err) => {
            Sentry.captureException(err, {
              extra: {
                isLoggedIn: !!userData?.uid,
                component: "ProfileUpdateForm"
              }
            });

            fetch("/api/auth/logout").finally(() => {
              toast.error("Some error occurred, please login again.");
              setUserData({});
              Router.reload();
            });
          });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Sentry.captureException(err);

        const errors = err?.response?.data?.non_field_errors;

        if (errors && (!(errors instanceof Array) || errors.length > 0)) {
          toast.error(errors);
        } else {
          toast.error(
            "Error occurred while updating profile. Please try again"
          );
        }
      });
  }

  function handleUpdateProfile(e) {
    e.preventDefault();
    setLoading(true);

    const payload = {
      fullname: e.target.fullname.value?.trim()
    };

    if(showEmailField) {
      payload.email = email;
    }

    updateProfile(payload, () => {
      setShouldUpdateProfile(false);
      setLoading(false);

      closeOnSuccess && handleCloseModal();
    });
  }

  function handleCloseModal() {
    closeModal ? closeModal() : LoginModalController().closeModal();
  }

  function renderProfileModal() {
    let profileModal = (
      <ModalWrapper closeModal={handleCloseModal}>
        <div className="flex flex-col h-full w-full">
          {/* Mobile Modal Header */}
          <div className="flex-1">
            <div className="font-bold text-[#22272B] leading-6">
              Enter your details
            </div>

            <form
              id="update-profile-form"
              className="mt-11 flex flex-col"
              onSubmit={handleUpdateProfile}
            >
              <input
                type="text"
                name="fullname"
                pattern="^[a-zA-Z ]*$"
                placeholder="Full Name"
                className="w-full text-[#22272B] text-lg pb-1 px-4 leading-8 bg-white active:bg-white placeholder-[#22272B] placeholder-opacity-20 border-b border-[#d6d6d6] outline-none focus:outline-none"
                value={fullname}
                required={true}
                autoComplete="off"
                disabled={loading}
                onChange={handleFullnameChange}
                onInvalid={(e) => {
                  e.preventDefault();
                  toast.error("Please enter a valid name");
                }}
              />
              {
                showEmailField && (
                  <input
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    className="mt-11 w-full text-[#22272B] text-lg pb-1 px-4 leading-8 bg-white active:bg-white placeholder-[#22272B] placeholder-opacity-20 border-b border-[#d6d6d6] outline-none focus:outline-none"
                    value={email}
                    required={true}
                    autoComplete="off"
                    disabled={loading}
                    onChange={handleEmailChange}
                    onInvalid={(e) => {
                      e.preventDefault();
                      toast.error("Please enter a valid email");
                    }}
                  />
                )
              }
            </form>
            {/* Mobile Modal Footer */}
            <button
              form="update-profile-form"
              className={cls(
                "w-full max-w-[124px] mt-9 font-bold leading-5 bg-[#5754F5] hover:bg-opacity-80 transition duration-150 rounded-full outline-none focus:outline-none",
                loading ? "opacity-40 cursor-not-allowed py-1" : "py-3",
                !isSubmitEnabled && "opacity-40 cursor-not-allowed"
              )}
              disabled={loading || !isSubmitEnabled}
            >
              {loading ? <div className="p-1.5"><Spinner size="xs" /></div> : "Next"}
            </button>
          </div>
          <PaymentBanner country={country} onAuthModal={true} />
        </div>
      </ModalWrapper>
    );

    return profileModal;
  }

  return renderProfileModal();
}

export default ProfileUpdateForm;
