import cls from "classnames";

export default function PaymentBanner({ country, onAuthModal, children }) {
  return (
    <div className="mt-10 sm:mt-[72px] mx-auto text-center max-w-[266px]">
      {children && (
        <div className="mx-auto mb-[1.25rem]">
          {children}
        </div>
      )}

      <div className="inline-flex justify-center items-center space-x-1">
        <img src="/assets/icons/shield-green.svg" className="w-6 h-6" alt="" />
        <h5
          className={cls(
            "text-sm leading-6",
            onAuthModal ? "text-black/80" : "text-white/80",
            'dark:text-[#161A1DD9]'
          )}
        >
          Fast and hassle-free payments
        </h5>
      </div>
      <div className={cls("mx-auto", onAuthModal ? "mt-[1.25rem]" : "mt-6")}>
        {country === "IN" ? (
          <img
            src="/assets/images/upi-payment-methods.png"
            className={cls("mx-auto", onAuthModal ? "h-[1.625rem]" : "")}
            alt=""
          />
        ) : (
          <img
            src="/assets/images/international-payment-methods.png"
            className={cls("mx-auto", onAuthModal ? "h-[1.625rem]" : "")}
            alt=""
          />
        )}
      </div>
    </div>
  );
}
