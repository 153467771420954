var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"89126f555c83525b6365ba16a991417d42b40f11"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://45205e3fc4804ea293ea90ed76f4ab3e@o661897.ingest.sentry.io/6053874",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.6,
    sampleRate: 0.8,
    // debug: process.env.USER_ENV !== "production",
    environment: process.env.USER_ENV || process.env.NODE_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
      "privateSpecialRepair",
      "getReadModeConfig",
      "getReadModeExtract",
      "getReadModeRender",
      'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating.
      'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML..
      'https://reactjs.org/docs/error-decoder.html?invariant=418' // Hydration failed because the initial UI does not...
    ],
  });
}
