import useStore from "../../../store/store";
import useSessionStore from "../../../store/sessionStore";
import { SHOW_PROFILE_SETUP } from "../../../constants/constance";
import Modal from "@qoohoo/common/library/modals/Modal";
import ProfileUpdateForm from './ProfileUpdateForm';

import LoginForm from "./LoginForm";
import SuperlinkOnboardingForm from "./SuperlinkOnboardingForm";

const LoginModal = () => {
  const LoginModalController = useStore((state) => state.LoginModalController);
  const SuperlinkOnboardingModalController = useStore(
    (state) => state.SuperlinkOnboardingModalController
  );
  const userData = useSessionStore((state) => state.userData);
  const loginModalState = useStore((state) => state.loginModalState);
  const superlinkOnboardingModalState = useStore(
    (state) => state.superlinkOnboardingModalState
  );

  const shouldUpdateProfile = useStore((state) => state.shouldUpdateProfile);

  function closeSuperlinkOnboardingModal() {
    LoginModalController().closeModal();
    SuperlinkOnboardingModalController().closeContent();
  }

  const isProfileUpdateContentVisible = (SHOW_PROFILE_SETUP && shouldUpdateProfile);

  const content = (
    <>
      {loginModalState.isContentOpened && (
        <LoginForm
          onClose={LoginModalController().closeModal}
          customConfig={loginModalState?.customConfig}
        />
      )}

      {
        isProfileUpdateContentVisible && <ProfileUpdateForm />
      }

      {superlinkOnboardingModalState.isContentOpened && (
        <SuperlinkOnboardingForm onClose={closeSuperlinkOnboardingModal} />
      )}
    </>
  );

  const isContentVisible = loginModalState.isContentOpened || isProfileUpdateContentVisible || superlinkOnboardingModalState.isContentOpened;

  let parentContent = null;

  if (loginModalState.isModalOpened) {
    parentContent = (
      <Modal
        isOpen={loginModalState.isModalOpened}
        closeModal={LoginModalController().closeModal}
        afterLeave={() => {}}
      >
        {content}
      </Modal>
    );
  } else if (isContentVisible) {
    parentContent = content;
  }

  return parentContent;
};

export default LoginModal;
