import cls from "classnames";
import useCountdown from "@qoohoo/common/hooks/useCountdown";

// User can send otp again after 30 seconds timer
const OTP_RESEND_TIMER_IN_SECONDS = 30;

export default function ResendOTP({ resendOtp }) {
  let resendOtpBody = null;
  const [timer, resetTimer] = useCountdown(OTP_RESEND_TIMER_IN_SECONDS);

  function handleResendOtp(viaWhatsapp) {
    if (typeof resendOtp === "function") {
      resendOtp(viaWhatsapp);
    }

    resetTimer();
  }

  if (timer > 0) {
    resendOtpBody = (
      <p
        className={cls(
          "text-xs leading-[150%] mt-6",
          "text-[#22272B] text-opacity-60"
        )}
      >
        Resend OTP in{" "}
        <span
          className={cls(
            "font-semibold",
            "text-black"
          )}
        >
          {timer}s
        </span>
      </p>
    );
  } else {
    resendOtpBody = (
      <div className="mt-10">
        <div className="flex justify-center items-center flex-nowrap">
          <p className="text-[#22272B] text-opacity-60 text-xs">
            Didn't receive OTP?
          </p>
          <button
            onClick={() => {
              handleResendOtp(false);
            }}
            className="ml-2 font-semibold text-[#605FE7] text-xs"
          >
            Resend
          </button>
        </div>
        <button
          onClick={() => {
            handleResendOtp(true);
          }}
          className="mt-3 font-semibold text-[#605FE7] text-xs"
        >
          Send OTP on WhatsApp
        </button>
      </div>
    );
  }

  return resendOtpBody;
}
