export function prepareModalState(
  isModalOpened = false,
  isContentOpened = false,
  params = null
) {
  return {
    ...params,
    isModalOpened,
    isContentOpened,
  };
}

export function ModalController(storeKey, set, get) {
  let { isModalOpened, isContentOpened, ...params } = get()[storeKey];

  function updateModalState() {
    set({
      [storeKey]: prepareModalState(isModalOpened, isContentOpened, params),
    });
  }

  function openModal(_params) {
    isModalOpened = true;

    openContent(_params);
  }

  function closeModal() {
    isModalOpened = false;

    closeContent();
  }

  function openContent(_params) {
    isContentOpened = true;
    params = _params || null;

    updateModalState();
  }

  function closeContent() {
    isContentOpened = false;
    params = null;

    updateModalState();
  }

  return {
    openModal,
    closeModal,
    openContent,
    closeContent,
  };
}
