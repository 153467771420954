import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const sessionStore = persist(
  (set) => ({
    userData: {},
    username: "",
    setUserData: (userData) => {
      set({ userData: userData });
    },
    setUsername: (username) => {
      set({ username: username });
    },
  }),
  {
    name: "user-data",
    serialize: (state) => btoa(encodeURIComponent(JSON.stringify(state))),
    deserialize: (storedState) =>
      decodeURIComponent(JSON.parse(atob(storedState))),
  }
);

const useSessionStore = create(devtools(sessionStore));

export default useSessionStore;
