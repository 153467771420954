import { useEffect, useState } from "react";

export default function useCountdown(duration) {
  const [timer, setTimer] = useState(duration);

  function resetCountdown() {
    setTimer(duration);
  }

  function startTimer() {
    if (timer > 0) {
      return setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }

  function resetTimer(interval) {
    // In case timer goes to negative value we need it to be 0
    if (timer < 0) {
      setTimer(0);
    }

    clearInterval(interval);
  }

  useEffect(() => {
    const interval = startTimer();

    return () => {
      resetTimer(interval);
    };
  }, [timer]);

  return [timer, resetCountdown];
}
