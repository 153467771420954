import posthog from "posthog-js";
import { isBrowser } from "../../constants/isBrowser";

function setUniqueUserID(id) {
  if (isBrowser) {
    posthog.identify(
      id, // distinct_id, required
      // { userProperty: "value1" }, // $set, optional
      // { anotherUserProperty: "value2" } // $set_once, optional
    );
  }
}

function removeUniqueUserID() {
  if (isBrowser) {
    posthog.reset();
  }
}

function addProperties(properties) {
  if (isBrowser) {
    posthog.people.set(properties);
  }
}

function pageView(label, data = {}) {
  if (isBrowser) {
    const { pathname, href } = window.location;
    const pageTitle = document.title;
    const pageUrl = new URL(href);
    const referer = pageUrl.searchParams.get("referer");
    const intent = pageUrl.searchParams.get("intent");

    const properties = {
      title: pageTitle,
      url: href,
      path: pathname,
      referer,
      intent,
      ...data,
    };

    posthog.capture(label, properties);
  }
}

function customEvent(type, data = {}) {
  if (isBrowser) {
    posthog.capture(type, {
      location: window.location.href,
      ...data,
    });
  }
}

function click(elementType, label, data = {}) {
  customEvent(label, {
    actionType: "click",
    elementType: elementType,
    label: label,
    ...data,
  });
}

function buttonClick(label, data = {}) {
  click("button", label, data);
}

function linkClick(label, data = {}) {
  click("link", label, data);
}

function success(label, description, data = {}) {
  customEvent(label, {
    actionType: "success",
    label: label,
    description: description,
    ...data,
  });
}

function error(label, description, data = {}) {
  customEvent(label, {
    actionType: "error",
    label: label,
    description: description,
    ...data,
  });
}

export {
  pageView,
  customEvent,
  click,
  buttonClick,
  linkClick,
  success,
  error,
  setUniqueUserID,
  removeUniqueUserID,
  addProperties,
};
