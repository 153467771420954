import { riseWebsiteDomain, websiteDomain } from "./src/constants/domain";
import { isProd } from "@qoohoo/common/constants/isProd";

const defaultSEOConfig = {
  siteName: isProd ? "qoohoo.in" : "staging.qoohoo.in",
  title: "qoohoo | earn from your social media and communities",
  description:
    "Monetise your audience across any platform - be it Whatsapp, Telegram, Slack and Discord. With the tool ecosystem at qoohoo - creators, professionals & businesses can grow their revenue by running memberships, courses, discounts and more. Get started now!",
  metaDescription:
    "Monetise your audience across any platform - be it Whatsapp, Telegram, Slack and Discord. With the tool ecosystem at qoohoo - creators, professionals & businesses can grow their revenue by running memberships, courses, discounts and more. Get started now!",
  image: `${websiteDomain}/assets/app-logo.svg`,
  bannerImage: `${websiteDomain}/assets/images/OG.png`,
};

const riseSEOConfig = {
  siteName: isProd ? "rise.qoohoo.in" : "rise.staging.qoohoo.in",
  title: "qoohoo | Monetise your audience on any platform",
  description:
    "Monetise your audience across any platform - be it Whatsapp, Telegram, Slack and Discord. With the tool ecosystem at qoohoo - creators, professionals & businesses can grow their revenue by running memberships, courses, discounts and more. Get started now!",
  metaDescription:
    "Monetise your audience across any platform - be it Whatsapp, Telegram, Slack and Discord. With the tool ecosystem at qoohoo - creators, professionals & businesses can grow their revenue by running memberships, courses, discounts and more. Get started now!",
  image: `${websiteDomain}/assets/app-logo.svg`,
  bannerImage: `${riseWebsiteDomain}/assets/images/OG.png`,
};

const subDomainsMap = {
  default: defaultSEOConfig,
  rise: riseSEOConfig,
};

function getCurrentSubDomainHost(route) {
  let currentSubDomainHost = "";

  const slugs = typeof route === "string" ? route.match(/[^\/]+/g) : null;
  const firstSlug = slugs instanceof Array ? slugs[0] : "";

  // Eg: '/_sites/rise'
  if (firstSlug === "_sites") {
    currentSubDomainHost = slugs[1];
  }

  return currentSubDomainHost;
}

export default function getSEOConfig(route) {
  const currentSubDomainHost = getCurrentSubDomainHost(route);
  let selectedConfigData = subDomainsMap[currentSubDomainHost];

  if (!selectedConfigData) {
    selectedConfigData = defaultSEOConfig;
  }

  const seoConfigTemplate = {
    title: selectedConfigData.title,
    description: selectedConfigData.metaDescription,
    openGraph: {
      type: "website",
      url: websiteDomain,
      title: selectedConfigData.title,
      description: selectedConfigData.description,
      site_name: selectedConfigData.siteName,
      images: [
        {
          url: selectedConfigData.bannerImage,
          width: 1810,
          height: 1006,
          alt: "qoohoo banner image",
          type: "image/png",
        },
      ],
    },
    twitter: {
      handle: "@qoohooapp",
      cardType: "summary_large_image",
      site: "@qoohooapp",
    },
    dangerouslyDisableGooglebot: !isProd,
    dangerouslySetAllPagesToNoIndex: !isProd,
    dangerouslySetAllPagesToNoFollow: !isProd,
  };

  return seoConfigTemplate;
}
