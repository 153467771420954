import { useEffect, useState } from "react";
import { pageview } from "@qoohoo/common/constants/analytics";

function getCurrentPageViewByUrl() {
  const [currentPage, setCurrentPage] = useState("");

  function getPageNameByUrl(url) {
    if (url.includes("/channels/")) {
      return pageview.channel_details;
    }

    if (url.includes("/by/")) {
      return pageview.profile;
    }

    return url;
  }

  useEffect(() => {
    const url = window.location.href;
    const pageName = getPageNameByUrl(url);

    if (currentPage) {
      setCurrentPage(pageName);
    }
  }, []);

  return currentPage;
}

export default getCurrentPageViewByUrl;
