import { format } from "date-fns";
import axios from "axios";

import {URL_PATTERN} from '../helpers/url-pattern';
import { WEBSITE_DOMAIN, CDN_DOMAIN } from "../constants/env";

export function attachProtocolIfMissing(url) {
  if(!url) {
    return url;
  }

  let urlWithProtocol = url;
  const hasProtocol = getProtocol(urlWithProtocol);

  urlWithProtocol = hasProtocol ? urlWithProtocol : "https://" + urlWithProtocol;

  return urlWithProtocol;
}

export function getProtocol(urlString) {
  if(!urlString) {
    return null;
  }

  const protocolRegex = /^(.+):/;

  const protocol = urlString.match(protocolRegex)?.[1] || null;

  return protocol;
}

export function linkifyText(inputText) {
  let replacedText, replacePattern1;

  // Change url pattern to link
  replacePattern1 = URL_PATTERN;
  replacedText = inputText.replace(
    replacePattern1,
    (match) => {
      if(match.indexOf('@') !== -1) {
        return `<a style="color: #3295F0" href="mailto:${match}" target="_blank">${match}</a>&nbsp;`
      }
        return `<a style="color: #3295F0" href="https://${match}" target="_blank">${match}</a>&nbsp;`
    }
  );

  return replacedText;
}

export function isImageOrVideo(file_url) {
  const types = new Map([
    ["png", "img"],
    ["jpeg", "img"],
    ["jpg", "img"],
    ["gif", "img"],
    ["mp4", "video"],
    ["mov", "video"],
    ["m4a", "video"],
    ["mp3", "audio"],
    ["wav", "audio"],
    ["ogg", "audio"],
  ]);
  const url = new URL(file_url);
  const extension = url.pathname.split(".")[1];
  return types.get(extension);
}

export function getOGImageType(url) {
  const imageFormat = url.split(".").pop();

  if (imageFormat === "jpg") {
    return "image/jpeg";
  } else if (imageFormat === "gif") {
    return "image/gif";
  } else {
    return "image/png";
  }
}


export function getQueryString(paramsObject) {
  const searchParams = new URLSearchParams();

  for(const [key, value] of Object.entries(paramsObject)) {
    searchParams.set(key, value);
  }

  const queryString = searchParams.toString();

  return queryString;
}

export function getCalendarLink(eventDetails) {
  const {title, description, startDate, endDate, url} = eventDetails;

  const baseUrl = "https://calendar.google.com/calendar/u/0/r/eventedit?";

  const params = new URLSearchParams();

  if(title) {
    params.set("text", title);
  }
  if(description) {
    params.set("details", description);
  }
  if(startDate && endDate) {
    const formatted_startDate=format(startDate, "yyyyMMdd") + "T" + format(startDate, "HHmmss");
    const formatted_endDate=format(endDate, "yyyyMMdd") + "T" + format(endDate, "HHmmss");
    

    params.set("dates", `${formatted_startDate}/${formatted_endDate}`)
  }
  if(url) {
    params.set("location", url);
  }

  return baseUrl + params.toString();
}

export function getZoomDetailsFromUrl(url) {
  let meetingNumber = "", password = "", zak = "";

  try {
    const _url = new URL(url);

    meetingNumber = _url.pathname.split("/").slice(-1)[0] || "";
    password = _url.searchParams.get("pwd") || "";
    zak = _url.searchParams.get("zak") || "";
  } catch (err) {
    console.error(err);
  }

  return {
    meetingNumber,
    password,
    zak
  }
}


export async function getIntegrationDetails(url, service, displayConfig, abortSignal){

  try {
  
  const payload = {
    url,
    service,
  };

  const axiosConfig = {};

  if(abortSignal) {
    axiosConfig.signal = abortSignal;
  }

  if(displayConfig) {
    payload.displayConfig = displayConfig;
  }

  const response = await axios.post("/api/get-embed/", payload, axiosConfig);

  const { data } = response;
  
  return data;
  } catch (err) {
    console.log(err);
    return Promise.reject();
  } 
}


export function updateHtmlAttribute(attribute, newValue, html) {
  const regexp = new RegExp(`${attribute}="[^"]*"`);

  let updatedHtml = html.replace(regexp, `${attribute}="${newValue}"`);

  return updatedHtml;
}

export function addParamToElementSrc(param, value, html) {
  try {
  let src = html.match(/src="([^"]+)"/)?.[1];
  
  if(!src) return html;
  
  const url = new URL(src);
  url.searchParams.set(param, value);
  
  const updatedSrc = url.toString();
  
  const updatedHtml = updateHtmlAttribute("src", updatedSrc, html);

  return updatedHtml;

  } catch (err) {
    return html;
  }
}

export function getMailToLink(email, subject, body) {
  let mailToLink = null;

  if (email) {
    mailToLink = "mailto:" + email;
  }

  if (subject) {
    const encodedSubject = encodeURIComponent(subject);

    mailToLink += "?subject=" + encodedSubject;
  }

  if (body) {
    const encodedBody = encodeURIComponent(body);

    mailToLink += "&body=" + encodedBody;
  }

  return mailToLink;
}

export function convertAssetPathToLink(path) {
  const link = `${WEBSITE_DOMAIN}${path}`;
  
  return link;
}
  
export function getCDNPrefixedUrl(path){
  path=path.replace('/^\//','');
  
  const link = `${CDN_DOMAIN}/${path}`
  
  return link;
}

export async function openInNewTab(url, delayDuration = 50) {
  if (!url || !window) {
    return;
  }

  async function delay() {
    new Promise((resolve, reject)=>{
      setTimeout(resolve, delayDuration);
    });
  }

  await delay();

  window.location.href = url;
}

export function getSlugs(path) {
  if (!path) return;

  if (path[0] === '/') {
    path = path.substr(1);
  }

  const slugs = path.split('/');

  return slugs;
}

export function objToQueryString(queryParams = {}) {
  let queryString, queryArr = [];
  const queryKeys = Object.keys(queryParams);

  for (let k = 0; k < queryKeys.length; k++) {
    const key = queryKeys[k];
    const val = queryParams[key];

    if(val != null && typeof val != "undefined") {
      const str = `${key}=${encodeURIComponent(queryParams[key])}`
      queryArr.push(str);
    }
  }

  queryString = queryArr.join('&');

  return queryString;
}

export function queryStringToObject(queryString = "") {
  return Object.fromEntries(new URLSearchParams(queryString));
}

export function hasParentWindow() {
  const _hasParentWindow = (window.location != window.parent.location);

  return _hasParentWindow;
}

export function getParentWindowUrl () {
  const parentWindowUrl = hasParentWindow() ? document.referrer : document.location.href;

  return parentWindowUrl;
}

export function getHostnameFromUrl(url) {
  const _url = new URL(url);
  const hostname = _url.hostname;

  return hostname;
}

export function getOriginFromUrl(url) {
  const _url = new URL(url);
  const origin = _url.origin;
  
  return origin;
}

export function prepareUrlWithQueryParams(url, queryParams){
  let urlWithQueryParams = url;

  if(queryParams && Object.keys(queryParams).length > 0) {
    const _url = new URL(url);
  
    const urlWithoutQueryParams = (_url.origin).concat(_url.pathname);
    
    const _queryParams = new URLSearchParams(_url.search);
  
    Object.entries(queryParams).forEach(([key, value]) => {
      _queryParams.set(key, value);
    })
  
    const queryString = _queryParams.toString();
  
    urlWithQueryParams = `${urlWithoutQueryParams}?${queryString}${_url.hash}`;  
  }

  return urlWithQueryParams;
}