import { useEffect } from "react";
import { hotjarInit } from "@qoohoo/common/utils/analytics";
import useSessionStore from "../store/sessionStore";

export default function useHotjar() {
  const userData = useSessionStore((state) => state.userData);

  useEffect(() => {
    hotjarInit(userData?.uid, userData?.username);
  }, []);
}
