import amplitude from "amplitude-js";
import * as Sentry from "@sentry/nextjs";
import { Amplitude, AmplitudeProvider } from "@amplitude/react-amplitude";
import { useEffect } from "react";
import useSessionStore from "../store/sessionStore";
import { isProd } from "@qoohoo/common/constants/isProd";

export default function AmplitudeAnlaytics({ children }) {
  const amplitudeInstance = amplitude.getInstance();
  const userData = useSessionStore((state) => state.userData);
  const userId = userData?.uid || null;

  useEffect(() => {
    if (amplitudeInstance) {
      amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, null, {
        userId: userId,
        logLevel: isProd ? "DISABLE" : "WARN",
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
        onError: (error) => {
          Sentry.captureException(error);
          console.error(error);
        },
      });

      if (amplitudeInstance.isNewSession()) {
        const visitor = new amplitudeInstance.Identify();
        visitor.setOnce("UserAgent", navigator.userAgent);
        amplitudeInstance.identify(visitor);
      }
    }
  }, []);

  if (typeof window !== "undefined") {
    return (
      <AmplitudeProvider
        amplitudeInstance={amplitudeInstance}
        apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}
        userId={userId}
      >
        <Amplitude
          eventProperties={{
            page: {
              url: location.href,
              path: location.pathname,
              origin: location.origin,
              search: location.search,
            },
          }}
        >
          {children}
        </Amplitude>
      </AmplitudeProvider>
    );
  }
}
