import cls from "classnames";
import { useEffect, useState } from "react";
import Spinner from "@qoohoo/common/library/loader/Spinner";

function GetOtpBtn(props) {
  const { isLoading, phoneNumber, selectedCountryCode, title, color, onClick, disabled=false} =
    props;
  const [isActive, setIsActive] = useState(false);

  function handleStateBasedOnCountry() {
    const isCountryCodeOfIndia = selectedCountryCode === "91";

    if (isCountryCodeOfIndia) {
      if (phoneNumber.length === 10) {
        setIsActive(true);
        return;
      }
    } else {
      if (phoneNumber.length > 4) {
        setIsActive(true);
        return;
      }
    }

    setIsActive(false);
  }

  useEffect(() => {
    handleStateBasedOnCountry();
  }, [phoneNumber, selectedCountryCode]);

  return (
    <button
      form="phone-input-form"
      className={cls(
        "relative overflow-hidden font-bold leading-5 w-full max-w-[320px] sm:max-w-[280px] mt-9  rounded-full z-[1] text-[13px]",
        isLoading ? "cursor-not-allowed opacity-50 py-1" : "py-3",
        isActive ? "hover:bg-opacity-90" : "opacity-40 cursor-not-allowed"
      )}
      onClick={onClick}
      style={{
        color: color.label,
        backgroundColor: color.bg,
        boxShadow: `0px 2px 0px ${color.shadow}`,
      }}
      disabled={disabled || isLoading || !isActive}
    >
      {isLoading ? <Spinner color={color.label} size="xs" /> : title}
    </button>
  );
}

GetOtpBtn.prototype.color = {
  SMS: {
    label: "#22272B",
    bg: "#F9F9F9",
    shadow: "#00000040",
  },
  WHATSAPP: {
    label: "#FFFFFF",
    bg: "#20BD57",
    shadow: "#13873E",
  },
};

export default GetOtpBtn;
