import cls from "classnames";

const ModalWrapper = ({ closeModal, children }) => {
  return (
    <div className="sm:inline-block sm:align-middle h-screen sm:h-full">
      <div className="relative inline-flex items-end justify-center w-full h-full sm:items-center font-nunito sm:py-14">
        <div
          className={cls(
            "relative bg-white sm:rounded-sm max-w-full w-full h-full sm:h-[526px] sm:max-w-[344px] sm:w-[344px]"
          )}
        >
          <img
            src="/assets/icons/cancel.svg"
            lt="close button"
            onClick={closeModal}
            className="absolute z-10 cursor-pointer select-none right-5 top-6"
          />
          <div className={cls("w-full h-full", "py-10 px-5 sm:py-6")}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
