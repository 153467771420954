const theme = {
  borderColor: "#605FE7",
  selectedBgColor: "#605FE7",
};

const Category = ({ category, isSelected, select }) => {
  const { emoji_unicode, display_name } = category;

  return (
    <button
      onClick={select}
      className="flex items-center gap-1 py-2 px-3 rounded-full border transition-all duration-150 bg-white hover:bg-[#605FE7] hover:text-white hover:border-[#605FE7]"
      style={{
        borderColor: isSelected ? theme.borderColor : "",
        background: isSelected ? theme.selectedBgColor : "",
        color: isSelected ? "white" : "",
      }}
    >
      <span className="font-mulish font-semibold text-[13px] leading-[16px]">
        {emoji_unicode}
      </span>
      <span className="font-nunito font-semibold text-[13px] leading-[16px]">
        {display_name}
      </span>
    </button>
  );
};

export default Category;
